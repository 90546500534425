import { useIntl } from 'react-intl';

import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useMobileContext, useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { IReviewAnswer } from '../../../../redux/slices/personalReviewSlice/types';
import { useAppSelector } from '../../../../redux/store';
import Divider from '../../../Divider';
import { QuestionsFeedback } from '../QuestionsFeedback';

export const ViewReviewSelfAssessment = () => {
  const { theme } = useThemeContext();
  const { deviceHeight } = useMobileContext();
  dayjs.extend(utc);
  const intl = useIntl();
  const { viewReview } = useAppSelector((state) => state.personalReview);
  const { selfAssessment, questions } = viewReview;

  const currentReviewOwner = selfAssessment?.owner;
  const currentReviewAnswers = selfAssessment?.answers;

  return (
    <Row className={`view-feedback-review-template widget-card`} style={{ height: `calc(${deviceHeight}px - 300px)` }}>
      <Col style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Col
          style={{
            paddingLeft: '1em',
            paddingRight: '1em',
            minHeight: selfAssessment?.isProvided && '120px',
            marginBottom: selfAssessment?.isProvided && '1em',
          }}
        >
          <Typography.Title level={4}>{intl.formatMessage({ id: 'SummarySelfAssessment' })}</Typography.Title>
          <Divider spacing={16} />
          {selfAssessment?.isProvided && (
            <>
              <div className='template-change'>
                <Typography
                  className={`typography-title-body ${theme}`}
                >{`${currentReviewOwner?.lastName} ${currentReviewOwner?.firstName}`}</Typography>
              </div>
              <Typography style={{ textAlign: 'center' }}>{intl.formatMessage({ id: 'submittedOn' })}</Typography>
              <Typography style={{ textAlign: 'center' }}>
                {dayjs.utc(selfAssessment?.updatedOn).local().format('DD/MM/YYYY HH:mm')}
              </Typography>
            </>
          )}
        </Col>
        <Col
          className={`review-template ${!selfAssessment?.isProvided ? 'empty-text' : ''}`}
          style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
        >
          {!selfAssessment?.isProvided && (
            <div>
              <Typography
                className={`typography-title-body ${theme}`}
                style={{ textAlign: 'center', fontWeight: 400, fontSize: '1rem' }}
              >
                {intl.formatMessage({ id: 'NothingToBeDisplayedHere' })}
              </Typography>
              <Divider spacing={16} />
            </div>
          )}
          {currentReviewAnswers?.map((answer: IReviewAnswer, id: number) => {
            const question = questions.find((q) => q.id === answer?.reviewQuestionId);

            return (
              <div key={id}>
                <QuestionsFeedback answerId={id} answer={{ ...answer, reviewQuestion: question }} />
              </div>
            );
          })}
        </Col>
      </Col>
    </Row>
  );
};

import { Col, Typography } from 'antd';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { WidgetComponent } from '../../../Widgets';
import './WidgetOverview.less';

interface IWidgetOverviewProps {
  count: number;
  description: string;
  onClick: () => void;
}

export const WidgetOverview = ({ count, description, onClick }: IWidgetOverviewProps) => {
  const { theme } = useThemeContext();
  return (
    <WidgetComponent bordered={false} className={`widget-overview card-widget ${theme} w-full h-full`}>
      <Col className={`widget-overview-content ${theme}`}>
        <Typography
          className={`widget-overview-body ${count > 0 && 'active'}`}
          onClick={count > 0 ? onClick : undefined}
        >
          {count} {description}
        </Typography>
      </Col>
    </WidgetComponent>
  );
};

import { Button } from 'antd';

import './buttonStyles.less';

// rename
export const BaseButton = (Component) => (props) => {
  return <Component {...props} />;
};
export const ButtonSimple = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>;
};

export const ButtonCustom = BaseButton(ButtonSimple);

import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Typography } from 'antd';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { updateReviewStatusAction } from '../../../../redux/slices/personalReviewSlice/actions';
import { ReviewStatus } from '../../../../redux/slices/personalReviewSlice/types';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useAuth } from '../../../../services/providers/AuthProvider';
import { allowPermision, userRoles } from '../../../../utils/userManagement';
import ModalComponent from '../../../Modal';
import { useModalContext } from '../../../Modal/ModalProvider';
import { LabelTag } from '../../../UIComponents/LabelTag';
import SingleSelectDropdown from '../../../UIComponents/SingleSelectDropdown';
import './SelectReviewStatus.less';

const getAvailableStatusOptions = (currentStatus: ReviewStatus): ReviewStatus[] => {
  switch (currentStatus) {
    case 'Scheduled':
      return ['Ongoing', 'Cancelled'];
    case 'Ongoing':
      return ['UnderReview', 'Cancelled'];
    case 'UnderReview':
      return ['Finished', 'Cancelled'];
    default:
      return [];
  }
};

export const SelectReviewStatus = () => {
  const { theme } = useThemeContext();
  const { isTopRoleModalOpen, setIsTopRoleModalOpen, setIsModalOpen } = useModalContext();
  const intl = useIntl();
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const employeeId = useAppSelector((state) => state.user?.loggedUser?.employeeId);
  const { viewReview } = useAppSelector((state) => state.personalReview);
  const { generalData, reviewersFeedback, settings, hrSummary } = viewReview;

  const [status, setStatus] = useState<ReviewStatus | null>(null);
  const [pendingStatus, setPendingStatus] = useState<ReviewStatus | null>(null);
  const [modalMessage, setModalMessage] = useState('');

  const isCreator = generalData?.createdBy?.employeeId === employeeId;
  const isAdmin = allowPermision(user.role, [userRoles.Admin]);
  const reviewId = generalData?.reviewId;

  const allowSelectionCheck = (isCreator || isAdmin) && status !== 'Finished' && status !== 'Cancelled';

  const allReviewersProvidedFeedback = reviewersFeedback?.every((reviewer) => {
    return reviewer.isProvided;
  });

  useEffect(() => {
    setStatus(generalData?.status);
  }, [generalData?.status]);

  const getModalMessage = (newStatus: ReviewStatus, currentStatus: ReviewStatus) => {
    switch (newStatus) {
      case 'Ongoing':
        return intl.formatMessage({ id: 'ScheduledToOngoingPopUpMessage' });
      case 'UnderReview':
        return settings?.allowFinishWithoutReviewersFeedback
          ? intl.formatMessage({ id: 'OngoingToUnderReviewPopUpMessage' })
          : intl.formatMessage({ id: 'OngoingToUnderReviewWithoutAllFeedbackPopUpMessage' });
      case 'Finished':
        return intl.formatMessage({ id: 'UnderReviewToFinishedPopUpMessage' });
      case 'Cancelled':
        return currentStatus === 'Scheduled'
          ? intl.formatMessage({ id: 'ScheduledToCancelledPopUpMessage' })
          : intl.formatMessage({ id: 'UnderReviewOngoingToCancelledPopUpMessage' });
      default:
        return '';
    }
  };

  const handleStatusChange = (newStatus: ReviewStatus) => {
    const confirmationMessage = getModalMessage(newStatus, status);
    const canAutoUpdate =
      (status === 'UnderReview' && newStatus !== 'Cancelled' && allReviewersProvidedFeedback) || hrSummary;

    if (newStatus === 'Finished' && !hrSummary) {
      setPendingStatus(newStatus);
      setModalMessage(confirmationMessage);
      setIsTopRoleModalOpen(true);
      return;
    }

    if (canAutoUpdate) {
      updateStatus(newStatus);
    } else {
      setPendingStatus(newStatus);
      setModalMessage(confirmationMessage);
      setIsTopRoleModalOpen(true);
    }
  };

  const updateStatus = (newStatus: ReviewStatus) => {
    const payload = { reviewId, status: newStatus };
    dispatch(updateReviewStatusAction(payload));
    setStatus(newStatus);
    setPendingStatus(null);
  };

  const reviewIncompleteToFinish =
    status === 'UnderReview' && pendingStatus === 'Finished' && !allReviewersProvidedFeedback && !hrSummary;

  const isFinishedWithoutHrSummary = pendingStatus === 'Finished' && !hrSummary;
  const canFinishWithoutFeedback = settings?.allowFinishWithoutReviewersFeedback || pendingStatus === 'Cancelled';
  const isOngoingWithoutPermission = pendingStatus === 'Ongoing' && !settings?.allowFinishWithoutReviewersFeedback;

  const shouldProceed =
    (!isFinishedWithoutHrSummary && !reviewIncompleteToFinish && canFinishWithoutFeedback) ||
    isOngoingWithoutPermission;

  const handleOnSubmit = () => {
    if (pendingStatus === 'Finished' && !hrSummary) {
      setIsTopRoleModalOpen(false);
      return;
    }

    if (
      reviewIncompleteToFinish ||
      (!settings?.allowFinishWithoutReviewersFeedback && pendingStatus !== 'Cancelled' && pendingStatus !== 'Ongoing')
    ) {
      setIsTopRoleModalOpen(false);
    } else {
      if (pendingStatus) updateStatus(pendingStatus);
    }
    setIsTopRoleModalOpen(false);
  };

  return (
    <>
      <div className={`select-status  ${theme}`}>
        {allowSelectionCheck ? (
          <SingleSelectDropdown
            dropdownAlign={{ offset: [30, null] }}
            theme={theme}
            label={<Typography>{status && intl.formatMessage({ id: status })}</Typography>}
            value={status}
            options={getAvailableStatusOptions(status).map((option) => ({
              value: option,
              label: intl.formatMessage({ id: option }),
            }))}
            onChange={handleStatusChange}
          />
        ) : (
          <LabelTag>
            <Typography>{status && intl.formatMessage({ id: status })}</Typography>
          </LabelTag>
        )}
      </div>
      <ModalComponent
        isOpen={isTopRoleModalOpen}
        isMaskClosable={false}
        width={500}
        title={intl.formatMessage({ id: 'ChangeStatus' })}
        children={<Typography style={{ fontWeight: '500' }}>{modalMessage}</Typography>}
        className='modal-select-status'
        submitBtnText={intl.formatMessage({
          id: shouldProceed ? 'yes' : 'btn_ok',
        })}
        cancelBtnText={intl.formatMessage({ id: 'no' })}
        onSubmit={handleOnSubmit}
        displayFooterCloseButton={shouldProceed}
        onCancelPressed={() => {
          setIsTopRoleModalOpen(false);
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

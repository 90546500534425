import { IEmployee } from '../../../redux/slices/colleaguesReviewSlice/types';
import {
  IFilters,
  IGetReviewsResponse,
  IReviewStatus,
  ISubmitFeedback,
  ISubmitHrSummary,
  IViewReviewResponse,
} from '../../../redux/slices/personalReviewSlice/types';
import { commonRequest } from '../../generic';
import { IAddReview, ICountOverview, IReviewTemplateResponse } from './types';

export default class ReviewProvider {
  async getReviewTemplates() {
    return await commonRequest<IReviewTemplateResponse[]>('GET', null, 'ReviewTemplate/getTemplates');
  }

  async getAllowedAssignees() {
    return await commonRequest<IEmployee[]>('GET', null, 'Review/getAllowedAssignees');
  }

  async getAllowedAssigneesReviewers(id: number) {
    return await commonRequest<IEmployee[]>('GET', null, `Review/getAllowedAssignees?subjectEmployeeId=${id}`);
  }

  async addReview(payload: IAddReview) {
    const payloadData = { payload };

    return await commonRequest<IAddReview>('POST', payloadData, 'Review/addReview');
  }

  async getMyPendingReviews(payload: IFilters | {}) {
    const payloadData = { payload };
    return await commonRequest<IGetReviewsResponse[]>('POST', payloadData, 'Review/getMyPendingReviews');
  }

  async getReviewById(id: number) {
    return await commonRequest<IViewReviewResponse>('GET', null, `Review/getReview/${id}`);
  }

  async submitFeedback(payload: ISubmitFeedback) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'Review/submitFeedback');
  }

  async submitHrSummary(payload: ISubmitHrSummary) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'Review/submitHrSummary');
  }

  async getAllReviews(payload: IFilters | {}) {
    const payloadData = { payload };
    return await commonRequest<IGetReviewsResponse[]>('POST', payloadData, 'Review/getAllReviews');
  }

  async getReviewCountOverview() {
    return await commonRequest<ICountOverview>('GET', null, 'Review/getReviewCountOverview');
  }

  async updateReviewStatus(payload: IReviewStatus) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'Review/updateStatus');
  }
}

import assetsReducer from 'redux/slices/assetsSlice';
import colleaguesMonthEventsReducer from 'redux/slices/colleaguesMonthEventsSlice';
import colleaguesReviewReducer from 'redux/slices/colleaguesReviewSlice';
import companySettingsReducer from 'redux/slices/companySettingsSlice';
import documentsReducer from 'redux/slices/documentsSlice';
import employeeReducer from 'redux/slices/employeeSlice';
import generalReducer from 'redux/slices/generalSlice';
import methodUpdateReducer from 'redux/slices/methodUpdateSlice';
import notificationReducer from 'redux/slices/notificationSlice';
import personalReviewReducer from 'redux/slices/personalReviewSlice';
import teamReducer from 'redux/slices/teamSlice';
import ticketingFiltersReducer from 'redux/slices/ticketingFilters';
import ticketingReducer from 'redux/slices/ticketingSlice';
import userReducer from 'redux/slices/userSlice';

const reducer = {
  user: userReducer,
  employees: employeeReducer,
  general: generalReducer,
  teams: teamReducer,
  methodUpdate: methodUpdateReducer,
  notification: notificationReducer,
  colleaguesMonthEvents: colleaguesMonthEventsReducer,
  ticketing: ticketingReducer,
  ticketingFilters: ticketingFiltersReducer,
  documents: documentsReducer,
  companySettings: companySettingsReducer,
  assets: assetsReducer,
  personalReview: personalReviewReducer,
  colleaguesReview: colleaguesReviewReducer,
};

export default reducer;

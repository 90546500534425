import { useIntl } from 'react-intl';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { setKeyValue } from '../../../../redux/slices/colleaguesReviewSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { ButtonCustom } from '../../../Buttons';
import './ColleaguesReviewsTabBar.less';

export const ColleaguesReviewsTabBar = () => {
  const { theme } = useThemeContext();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { activeTab, tabs, generalSettings } = useAppSelector((state) => state.colleaguesReview);

  const selectHandleButton = (label: string) => {
    dispatch(setKeyValue({ key: 'activeTab', value: label }));
  };

  const tabConditions = {
    goalsAndKPIs: generalSettings?.enableGoalsAndObjectives,
  };

  const filterTabs = tabs.filter((key) => tabConditions[key] !== false);

  return (
    <div className='colleagues-reviews-tab-bar'>
      {filterTabs.map((key: string) => (
        <ButtonCustom
          key={key}
          className={`text-bold-normal btn-default-custom ${theme} ${activeTab === key ? 'active' : ''}`}
          onClick={() => selectHandleButton(key)}
        >
          {intl.formatMessage({ id: key })}
        </ButtonCustom>
      ))}
    </div>
  );
};

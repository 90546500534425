import { Checkbox } from 'antd';

import { useThemeContext } from '../../../AppProvider/ConfigProviderSettings';
import { ICustomOptionProps } from '../type';
import './CustomMultipleChoice.less';

export const CustomMultipleChoice = (props: ICustomOptionProps) => {
  const { theme } = useThemeContext();
  const { answerOptions, onChange, disabled, value } = props;

  const options = answerOptions.map((option, id) => {
    return { label: option.answerOption, value: option.id };
  });

  return (
    <Checkbox.Group
      className={`custom-checkbox ${theme}`}
      options={options}
      onChange={(checkedValues) => onChange(checkedValues as number[])}
      disabled={disabled}
      value={value as number[]}
    />
  );
};

export type INotification = {
  createdDate: Date;
  employeeId: number;
  message: string;
  title: string;
  type: NotificationType;
  actionType: NotificationActionType;
  notificationId: number;
  readStatus: boolean;
  requestId?: number;
  ticketId?: number;
  reviewId?: number;
};

export enum NotificationType {
  None= 0,
  Vacation = 1,
  Unpaid = 2,
  WorkFromHome = 3,
  Medical = 4,
  Paternity = 5,
  Maternity = 6,
  Childcare = 7,
  PersonalLeave = 8,
  LeaveOffsetting = 9,
  Overtime = 10,
  BloodDonation = 11,
  Bereavement = 12,
  Marriage = 13,
  ForgotBadge = 14,
  MilitaryTraining = 15,
  TwoOrMoreChildren = 16,
  BusinessTrip = 17,
  FirstDayOfSchool = 18,
  LastDayOfSchool = 19,
  ChildcareLeaveUnpaid = 20,
  BirthOfAChild = 21,
  LeaveOffsetReminder = 100,
  Ticketing = 101,
  Review = 102
}

export enum NotificationActionType {
  None,
  AwaitingAction,
  InformingAction,
  InformingActionWithRedirect
}

import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Typography } from 'antd';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { CreateReviewLightIcon } from '../../../../Icons/Review/createReviewLightIcon';
import { CreateReviewDarkIcon } from '../../../../Icons/Review/cretateReviewDarkIcon';
import { resetState, setToolbarReview } from '../../../../redux/slices/colleaguesReviewSlice';
import { initialGeneralData, initialGeneralSettings } from '../../../../redux/slices/colleaguesReviewSlice';
import { addReviewAction } from '../../../../redux/slices/colleaguesReviewSlice/actions';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useAuth } from '../../../../services/providers/AuthProvider';
import { notifyWithError, notifyWithSuccessfulDataUpdated } from '../../../../utils/notificationsUtils';
import { allowPermision, userRoles } from '../../../../utils/userManagement';
import ModalComponent from '../../../Modal';
import { useModalContext } from '../../../Modal/ModalProvider';
import SearchToolbarComponent from '../../../UIComponents/SearchToolbar';
import { ColleaguesReviewsToolbarFilters } from '../ColleaguesReviewsToolbarFilters';
import './ColleaguesReviewsToolbar.less';

export const ColleaguesReviewsToolbar = ({ form }) => {
  const { theme } = useThemeContext();
  const { isTopRoleModalOpen, setIsTopRoleModalOpen } = useModalContext();

  const dispatch = useAppDispatch();
  const intl = useIntl();

  const {
    toolbarReview,
    generalData,
    generalSettings,
    customQuestions,
    reviewTemplates,
    currentTemplateIndex,
    textGoalsAndKPIs,
  } = useAppSelector((state) => state.colleaguesReview);

  const { create, back } = toolbarReview;
  const { subjectEmployee, reviewers, startDate, endDate } = generalData;
  const { user } = useAuth();

  const isAdminAndIsHR = allowPermision(user.role, [userRoles.Admin, userRoles.HR]);

  useEffect(() => {
    form.setFieldsValue({
      subjectEmployee: subjectEmployee,
      reviewEmployees: reviewers,
      dates: startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null,
    });
  }, [generalData, form]);

  const currentTemplateId = reviewTemplates[currentTemplateIndex]?.reviewTemplateId;

  const payload = {
    templateId: currentTemplateId,
    subjectEmployeeId: subjectEmployee,
    settings: generalSettings,
    startDate: startDate,
    endDate: endDate,
    customQuestions: customQuestions,
    reviewersEmployeeIds: reviewers,
    goalsAndObjectives: textGoalsAndKPIs,
  };

  const handleSaveAndStart = async () => {
    if (payload.startDate && payload.endDate && payload.subjectEmployeeId && payload.reviewersEmployeeIds.length > 0) {
      if (payload.settings.enableGoalsAndObjectives && payload.goalsAndObjectives === '') {
        notifyWithError({
          message: intl.formatMessage({ id: 'ValidationFailed' }),
          description: intl.formatMessage({ id: 'SetGoalsAndKPIsValidation' }),
        });
        return;
      }
      try {
        await dispatch(addReviewAction(payload)).unwrap();
        notifyWithSuccessfulDataUpdated('', intl.formatMessage({ id: 'SaveAndStartSuccessMessage' }));
        dispatch(resetState());
      } catch (error) {
        notifyWithError({
          message: intl.formatMessage({ id: 'Error' }),
          description: intl.formatMessage({ id: 'ServerError' }),
        });
      }
    } else {
      form.validateFields();
      notifyWithError({
        message: intl.formatMessage({ id: 'ValidationFailed' }),
        description: intl.formatMessage({ id: 'SaveAndStartErrorValidation' }),
      });
    }
  };

  const handleBack = () => {
    dispatch(
      setToolbarReview({
        ...toolbarReview,
        create: false,
        back: true,
        saveAndStart: back,
      })
    );
    dispatch(resetState());
  };

  const withoutModification =
    isEqual(customQuestions, []) &&
    isEqual(generalData, initialGeneralData) &&
    isEqual(generalSettings, initialGeneralSettings);

  return (
    <>
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        className={`colleagues-reviews-toolbar ${!create ? 'toolbar-filters' : ''}`}
        children={!create && <ColleaguesReviewsToolbarFilters />}
        buttons={[
          {
            icon: theme === 'light' ? <CreateReviewDarkIcon /> : <CreateReviewLightIcon />,
            text: intl.formatMessage({ id: 'create' }),
            action: () => {
              dispatch(
                setToolbarReview({
                  ...toolbarReview,
                  create: !create,
                  back: false,
                  saveAndStart: false,
                })
              );
            },
            show: !create && isAdminAndIsHR,
          },
          {
            text: intl.formatMessage({ id: 'back' }),
            action: () => {
              if (withoutModification) {
                handleBack();
              } else {
                setIsTopRoleModalOpen(true);
              }
            },
            isBetween: create,
          },
          {
            text: intl.formatMessage({ id: 'saveAndStart' }),
            action: () => handleSaveAndStart(),
            show: create,
          },
        ]}
      />
      <ModalComponent
        isOpen={isTopRoleModalOpen}
        className={'modal-warning'}
        isMaskClosable={false}
        width={400}
        title={intl.formatMessage({ id: 'Warning' })}
        children={
          <Typography style={{ fontWeight: '500' }}>{intl.formatMessage({ id: 'ReviewBackPopUpMessage' })}</Typography>
        }
        submitBtnText={intl.formatMessage({ id: 'yes' })}
        cancelBtnText={intl.formatMessage({ id: 'no' })}
        onSubmit={() => {
          handleBack();
          setIsTopRoleModalOpen(false);
        }}
        onCancelPressed={() => setIsTopRoleModalOpen(false)}
      />
    </>
  );
};

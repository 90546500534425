import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Col, Form, Input, Typography } from 'antd';

import { useMobileContext, useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { setKeyValue } from '../../../../redux/slices/personalReviewSlice';
import { submitFeedbackAction } from '../../../../redux/slices/personalReviewSlice/actions';
import { getReviewByIdAction } from '../../../../redux/slices/personalReviewSlice/actions';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useAuth } from '../../../../services/providers/AuthProvider';
import { notifyWithError, notifyWithSuccessfulDataUpdated } from '../../../../utils/notificationsUtils';
import { allowPermision } from '../../../../utils/userManagement';
import { userRoles } from '../../../../utils/userManagement';
import { ButtonCustom } from '../../../Buttons';
import { CustomMultipleChoice, CustomSingleChoice, CustomSlider } from '../../../CustomOptions';
import Divider from '../../../Divider';
import { ReviewNotes } from '../ReviewNotes';
import './ViewReviewTemplate.less';

export const ViewReviewTemplate = () => {
  const { theme } = useThemeContext();
  const { deviceHeight } = useMobileContext();
  const { user } = useAuth();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const { viewReview, answers, activeReview } = useAppSelector((state) => state.personalReview);
  const employeeId = useAppSelector((state) => state.user?.loggedUser?.employeeId);

  const { questions, generalData, reviewersFeedback, selfAssessment } = viewReview;

  const feedbackProviders = reviewersFeedback || [];

  const feedbackReview = feedbackProviders.find((review) => review.owner.employeeId === employeeId);
  const hasReviewProvided = feedbackReview?.isProvided;
  const isEmployeeIncludedInReview = feedbackProviders.some((review) => review.owner.employeeId === employeeId);
  const selfAssessmentProvided = selfAssessment?.isProvided && selfAssessment?.owner?.employeeId === employeeId;
  const isAdmin = allowPermision(user.role, userRoles.Admin);
  const isHr = allowPermision(user.role, userRoles.HR);

  useEffect(() => {
    form.resetFields();
  }, [generalData?.reviewId]);

  const handleAnswerOnChange = (questionId: number, value: string | number | number[]) => {
    const newAnswer = {
      questionId,
      textAnswer: typeof value === 'string' ? value : '',
      selectedAnswerOptions: typeof value === 'string' ? [] : Array.isArray(value) ? value : [value],
    };

    let updatedAnswers = answers.filter((item) => item.questionId !== questionId);

    if (newAnswer.textAnswer || newAnswer.selectedAnswerOptions.length > 0) {
      updatedAnswers = [...updatedAnswers, newAnswer];
    }

    updatedAnswers.sort((a, b) => a.questionId - b.questionId);

    dispatch(setKeyValue({ key: 'answers', value: updatedAnswers }));
  };

  const handleSaveAndSend = async () => {
    try {
      await form.validateFields();

      if (answers.length === questions.length) {
        const payload = {
          reviewId: generalData.reviewId,
          answers,
        };

        const result = await dispatch(submitFeedbackAction(payload)).unwrap();
        await dispatch(getReviewByIdAction(payload.reviewId));

        dispatch(setKeyValue({ key: 'activeReview', value: false }));
        dispatch(setKeyValue({ key: 'answers', value: [] }));
        notifyWithSuccessfulDataUpdated('', 'Feedback submitted successfully!');
      }
    } catch (error) {
      notifyWithError({ message: 'Error', description: 'Failed to submit feedback!' });
    }
  };

  const isDisabled =
    !activeReview ||
    hasReviewProvided ||
    generalData?.status !== 'Ongoing' ||
    (selfAssessmentProvided && !isEmployeeIncludedInReview) ||
    (isAdmin && !isEmployeeIncludedInReview) ||
    (isHr && !isEmployeeIncludedInReview);

  const isButtonVisible =
    activeReview &&
    !hasReviewProvided &&
    generalData?.status === 'Ongoing' &&
    (!selfAssessmentProvided || isEmployeeIncludedInReview) &&
    (!isAdmin || (isAdmin && isEmployeeIncludedInReview)) &&
    (!isHr || (isHr && isEmployeeIncludedInReview));

  return (
    <Form
      form={form}
      className={`view-review-template widget-card`}
      style={{ height: `calc(${deviceHeight}px - 300px)` }}
    >
      <Col style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Col style={{ paddingLeft: '1em', paddingRight: '1em' }}>
          <Typography.Title level={4}>{intl.formatMessage({ id: 'ReviewTheRequiredFeedback' })}</Typography.Title>
          <Divider spacing={16} />
        </Col>
        <Col className={'review-template'}>
          {questions?.map((question, id) => {
            return (
              <div key={id}>
                <Col>
                  <Col>
                    <Typography className={`typography-title-body ${theme}`}>
                      {intl.formatMessage({ id: 'Question' })} #{id + 1}
                    </Typography>
                    <Typography.Paragraph className={`typography-paragraph ${theme}`}>
                      {question?.questionText}
                    </Typography.Paragraph>
                    <ReviewNotes question={question} />
                  </Col>
                  <Col>
                    <Typography className={`typography-title-body ${theme}`}>
                      {intl.formatMessage({ id: 'Answer' })} #{id + 1}
                    </Typography>
                    {question?.answerType === 'Text' && (
                      <Typography.Paragraph className={`typography-paragraph ${theme}`}>
                        <Form.Item
                          initialValue={answers.find((a) => a.questionId === question.id)?.textAnswer || ''}
                          name={`answer-${id}`}
                          rules={[
                            {
                              required: true,
                              message: 'Please input your answer!',
                            },
                          ]}
                        >
                          <Input.TextArea
                            className={`view-review-input ${theme}`}
                            autoSize={{ minRows: 1, maxRows: 4 }}
                            disabled={isDisabled}
                            maxLength={500}
                            {...(hasReviewProvided && { value: feedbackReview?.answers?.[id].textAnswer })}
                            onChange={(e) => handleAnswerOnChange(question?.id, e.target.value)}
                          />
                        </Form.Item>
                      </Typography.Paragraph>
                    )}
                    {question?.answerType === 'Rating' && (
                      <Form.Item
                        initialValue={answers.find((a) => a.questionId === question.id)?.selectedAnswerOptions?.[0]}
                        className='slider-form-item'
                        name={`answer-${id}`}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your answer!',
                          },
                        ]}
                      >
                        <CustomSlider
                          answerOptions={question?.answerOptions}
                          onChange={(value) => handleAnswerOnChange(question?.id, value)}
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    )}
                    {question?.answerType === 'SingleChoice' && (
                      <Form.Item
                        name={`answer-${id}`}
                        initialValue={answers.find((a) => a.questionId === question.id)?.selectedAnswerOptions?.[0]}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your answer!',
                          },
                        ]}
                      >
                        <CustomSingleChoice
                          answerOptions={question?.answerOptions}
                          onChange={(value) => handleAnswerOnChange(question?.id, value)}
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    )}
                    {question?.answerType === 'MultipleChoice' && (
                      <Form.Item
                        initialValue={answers.find((a) => a.questionId === question.id)?.selectedAnswerOptions}
                        name={`answer-${id}`}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your answer!',
                          },
                        ]}
                      >
                        <CustomMultipleChoice
                          answerOptions={question?.answerOptions}
                          onChange={(value) => handleAnswerOnChange(question?.id, value)}
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Col>
              </div>
            );
          })}
          {isButtonVisible && (
            <Col style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Col className={'view-review-template-button'}>
                <ButtonCustom
                  className={`text-bold-normal btn-default-custom ${theme}`}
                  style={{ width: '100%', marginBottom: '5px' }}
                  onClick={handleSaveAndSend}
                >
                  {intl.formatMessage({ id: 'saveAndSend' })}
                </ButtonCustom>
              </Col>
            </Col>
          )}
        </Col>
      </Col>
    </Form>
  );
};

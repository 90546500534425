import { useThemeContext } from '../../../AppProvider/ConfigProviderSettings';
import { useMobileContext } from '../../../AppProvider/ConfigProviderSettings';
import { useDynamicRows } from '../../../hooks/useDynamicRows';
import { useAppSelector } from '../../../redux/store';
import { WidgetComponent } from '../../Widgets';
import './ViewReview.less';
import { ViewReviewAfterSubmit } from './ViewReviewAfterSubmit';
import { ViewReviewFeedbackReviewers } from './ViewReviewFeedbackReviewers';
import { ViewReviewGeneralSettings } from './ViewReviewGeneralSettings';
import { ViewReviewGoalsAndKPIs } from './ViewReviewGoalsAndKPIs';
import { ViewReviewHrSummary } from './ViewReviewHrSummary';
import { ViewReviewSelfAssessment } from './ViewReviewSelfAssessment';
import { ViewReviewSummary } from './ViewReviewSummary';
import { ViewReviewTabBar } from './ViewReviewTabBar';
import { ViewReviewTemplate } from './ViewReviewTemplate';

export const ViewReviewContent = () => {
  const { theme } = useThemeContext();
  const { deviceHeight } = useMobileContext();

  const employeeId = useAppSelector((state) => state.user?.loggedUser?.employeeId);
  const { activeTab, viewReview } = useAppSelector((state) => state.personalReview);
  const { reviewersFeedback, selfAssessment } = viewReview;
  const { generalData, hrSummary } = viewReview;

  const feedbackProviders = reviewersFeedback || [];

  const feedbackReview = feedbackProviders.find((review) => review.owner.employeeId === employeeId);
  const hasReviewProvided = feedbackReview?.isProvided;
  const selfAssessmentProvided = selfAssessment?.isProvided && selfAssessment?.owner?.employeeId === employeeId;

  const offSetForHeight = generalData?.status === 'UnderReview' && !hrSummary ? 400 : 325;
  const maxRowsHrSummary = useDynamicRows({ deviceHeight, offsetHeight: offSetForHeight, minRows: 20 });
  const maxRowsGoalsAndKPIs = useDynamicRows({ deviceHeight, offsetHeight: 325, minRows: 20 });

  const tabComponents = {
    review: hasReviewProvided || selfAssessmentProvided ? <ViewReviewAfterSubmit /> : <ViewReviewTemplate />,
    generalSettings: <ViewReviewGeneralSettings />,
    goalsAndKPIs: <ViewReviewGoalsAndKPIs maxRows={maxRowsGoalsAndKPIs} />,
    showReviewersFeedbackSummary: <ViewReviewFeedbackReviewers />,
    selfAssessment: <ViewReviewSelfAssessment />,
    hrSummary: <ViewReviewHrSummary maxRows={maxRowsHrSummary} />,
  };

  return (
    <WidgetComponent
      style={{
        height: `calc(${deviceHeight}px - 186px)`,
        overflowY: 'hidden',
      }}
      bordered={false}
      className={`card-widget wrapper-profile-col ${theme} full-content`}
      cover={
        <div className='view-review-content'>
          <ViewReviewTabBar />
          <div className='view-review-body'>
            {tabComponents[activeTab]}
            {activeTab in tabComponents && <ViewReviewSummary />}
          </div>
        </div>
      }
    />
  );
};

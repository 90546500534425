import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { setKeyValue } from '../../../../redux/slices/personalReviewSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useAuth } from '../../../../services/providers/AuthProvider';
import { allowPermision } from '../../../../utils/userManagement';
import { userRoles } from '../../../../utils/userManagement';
import { ButtonCustom } from '../../../Buttons';
import './ViewReviewTabBar.less';

export const ViewReviewTabBar = () => {
  const { theme } = useThemeContext();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { activeTab, tabs, viewReview } = useAppSelector((state) => state.personalReview);
  const { displaySettings } = viewReview;

  const isAdminOrHR = allowPermision(user.role, [userRoles.Admin, userRoles.HR]);

  const selectHandleButton = (label: string) => {
    dispatch(setKeyValue({ key: 'activeTab', value: label }));
  };

  const tabConditions = {
    review: displaySettings?.showReviewerFeedback || isAdminOrHR,
    generalSettings: displaySettings?.showSettings,
    goalsAndKPIs: displaySettings?.showGoalsAndObjectives,
    showReviewersFeedbackSummary: displaySettings?.showReviewersFeedbackSummary,
    selfAssessment: displaySettings?.showSelfAssessment,
    hrSummary: displaySettings?.showHrSummary,
  };

  const filteredTabs = tabs.filter((key) => tabConditions[key] !== false);

  useEffect(() => {
    if (!filteredTabs.includes(activeTab) && filteredTabs.length > 0) {
      dispatch(setKeyValue({ key: 'activeTab', value: filteredTabs[0] }));
    }
  }, [activeTab, filteredTabs]);

  return (
    <div className='view-review-tab-bar'>
      {filteredTabs.map((key: string) => (
        <ButtonCustom
          key={key}
          className={`text-bold-normal btn-default-custom ${theme} ${activeTab === key ? 'active' : ''}`}
          onClick={() => selectHandleButton(key)}
        >
          {intl.formatMessage({ id: key })}
        </ButtonCustom>
      ))}
    </div>
  );
};

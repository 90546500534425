import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Form, Input, Row, Typography } from 'antd';

import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import { setKeyValue } from '../../../../redux/slices/colleaguesReviewSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import Divider from '../../../Divider';
import './ColleaguesReviewGoalsAndKPIs.less';

export const ColleaguesReviewGoalsAndKPIs = ({ form, maxRows }) => {
  const { deviceHeight } = useMobileContext();
  const dispatch = useAppDispatch();
  const { generalSettings, textGoalsAndKPIs } = useAppSelector((state) => state.colleaguesReview);
  const { enableGoalsAndObjectives } = generalSettings;

  const [inputValue, setInputValue] = useState<string>(textGoalsAndKPIs);
  const intl = useIntl();

  useEffect(() => {
    setInputValue(textGoalsAndKPIs);
    form.setFieldsValue({ goalsAndKPIs: textGoalsAndKPIs });
  }, [textGoalsAndKPIs, form]);

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleOnBlur = () => {
    dispatch(setKeyValue({ key: 'textGoalsAndKPIs', value: inputValue }));
  };

  return (
    <Row className='colleagues-review-goals widget-card' style={{ height: `calc(${deviceHeight}px - 300px)` }}>
      <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
        <Typography.Title level={4}>{intl.formatMessage({ id: 'SetUpGoalsAndKPIs' })}</Typography.Title>
        <Divider spacing={16} />
      </div>
      <Form form={form} className='colleagues-review-goals-form'>
        <Form.Item
          name='goalsAndKPIs'
          initialValue={textGoalsAndKPIs}
          rules={
            enableGoalsAndObjectives && [
              {
                required: true,
                message: 'Please enter your goals and KPIs',
              },
            ]
          }
        >
          <Input.TextArea
            style={{ width: '100%' }}
            autoSize={{ minRows: maxRows, maxRows: maxRows }}
            maxLength={4000}
            showCount
            value={inputValue}
            disabled={!enableGoalsAndObjectives}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
        </Form.Item>
      </Form>
    </Row>
  );
};

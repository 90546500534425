import { useIntl } from 'react-intl';

import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';

import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import styles from '../../../../pages/Ticketing/TicketingDetailedView/Layout/TicketsDetailsSection/ticketsDetailsSection.module.less';
import { useAppSelector } from '../../../../redux/store';
import { SelectReviewStatus } from '../SelectReviewStatus';
import './ViewReviewSummary.less';

export const ViewReviewSummary = () => {
  const { deviceHeight } = useMobileContext();
  const intl = useIntl();

  const { viewReview } = useAppSelector((state) => state.personalReview);
  const { generalData, reviewersFeedback } = viewReview;

  const subject = generalData?.subject;
  const createdBy = generalData?.createdBy;

  return (
    <div className={`view-review-summary`} style={{ height: `calc(${deviceHeight}px - 300px)` }}>
      <div
        style={{
          overflow: 'auto',
          height: '100%',
          marginRight: '-8px',
        }}
      >
        <div className={`view-review-summary-sections`}>
          <Row className='review-name'>
            <Col>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'ReviewName' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography className='name-text'>{generalData?.name}</Typography>
            </Col>
          </Row>
          <Row className='review-type'>
            <Col>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'Type' })}
              </Typography.Title>
            </Col>
            <Col>
              {generalData?.type && (
                <Typography className='type-text'>{intl.formatMessage({ id: generalData?.type })}</Typography>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'Description' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{generalData?.description}</Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24} className='review-status'>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'ReviewStatus' })}
              </Typography.Title>
              <SelectReviewStatus />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'startDate' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{dayjs(generalData?.startDate).format('DD MMMM YYYY')}</Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'endDate' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{dayjs(generalData?.endDate).format('DD MMMM YYYY')}</Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'subjectEmployee' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>
                {`${subject?.lastName} ${subject?.firstName}`}, {subject?.email}
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'Function' })}
              </Typography.Title>
            </Col>
            <Col>{subject?.position && <Typography>{intl.formatMessage({ id: subject.position })}</Typography>}</Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'TeamsOfTheEmployee' })}
              </Typography.Title>
            </Col>
            <Col style={{ paddingRight: '5px' }}>
              {subject?.teams !== undefined && Object.keys(subject?.teams).length > 0 ? (
                <Typography>{Object.values(subject?.teams).join(', ')}</Typography>
              ) : (
                <Typography>-</Typography>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'reviewers' })}
              </Typography.Title>
            </Col>
            <Col style={{ paddingRight: '5px' }}>
              <Typography>
                {reviewersFeedback
                  ?.map((reviewer) => `${reviewer?.owner?.lastName} ${reviewer?.owner?.firstName}`)
                  .join(', ')}
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'ReviewCreator' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{`${createdBy?.lastName} ${createdBy?.firstName}`}</Typography>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export const ArrowTop = (props) => (
  <span role='img' aria-label='wfh' className='anticon anticon-wfh' {...props}>
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ verticalAlign: 'bottom' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7388 8.8033C11.3308 8.39889 10.6692 8.39891 10.2612 8.8033L7.30602 11.7322C6.898 12.1366 6.89798 12.7923 7.30602 13.1967C7.71407 13.6011 8.37559 13.6011 8.78361 13.1967L11 11L13.2164 13.1967C13.6244 13.6011 14.286 13.6011 14.694 13.1967C15.102 12.7923 15.102 12.1366 14.694 11.7322L11.7388 8.8033Z'
        fill='currentColor'
      ></path>
    </svg>
  </span>
);

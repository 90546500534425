import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import './index.less';
import { getPlaceholder, renderContent } from './utils';

const HeaderDropDownFilter = ({ value, placeholder, dataForOptions }) => {
  const intl = useIntl();
  const disableTitleOfFilter = false;
  const selectedOptions = value;
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  const placeholderText = getPlaceholder({
    placeholder,
    selectedOptions,
    dataForOptions,
    intl,
    disableTitleOfFilter,
  });

  const textRef = useRef(null);
  //This useEffect is responsable to determine when need to apply tooltip.
  useEffect(() => {
    const handleResize = () => {
      let element = textRef.current;

      if (element) {
        // setIsEllipsisActive(element.clientHeight < element.scrollHeight);
        //New approach set tooltip for every case
        setIsEllipsisActive(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [placeholderText.placeholder]);
  return (
    <>
      {isEllipsisActive}
      <span className='header_text' ref={textRef}>
        {renderContent(placeholderText.placeholder, isEllipsisActive)}
      </span>
    </>
  );
};

export default HeaderDropDownFilter;

import { useEffect } from 'react';

import { Slider } from 'antd';

import { useThemeContext } from '../../../AppProvider/ConfigProviderSettings';
import { ICustomOptionProps } from '../type';
import './CustomSlider.less';

export const CustomSlider = (props: ICustomOptionProps) => {
  const { theme } = useThemeContext();
  const { answerOptions, onChange, disabled, value } = props;

  const options = answerOptions.reduce(
    (acc, option) => {
      acc[option.id] = option.answerOption;
      return acc;
    },
    {} as Record<number, string>
  );

  const min = Math.min(...answerOptions.map((option) => option.id));
  const max = Math.max(...answerOptions.map((option) => option.id));

  useEffect(() => {
    const activeMarks = document.querySelectorAll('.ant-slider-mark-text-active') as NodeListOf<HTMLElement>;

    if (activeMarks.length > 0) {
      const lastActiveMark = activeMarks[activeMarks.length - 1];
      lastActiveMark.style.fontWeight = '500';
    }

    return () => {
      activeMarks.forEach((mark) => {
        mark.style.fontWeight = '';
      });
    };
  }, [value]);

  return (
    <Slider
      className={`custom-slider ${theme}`}
      marks={options}
      min={min}
      max={max}
      defaultValue={null}
      tooltip={{ open: false }}
      disabled={disabled}
      value={value as number}
      step={null}
      onChange={onChange}
    />
  );
};

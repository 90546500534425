import { createAsyncThunk } from '@reduxjs/toolkit';

import { ReviewService } from '../../../services';
import { IFilters, IReviewStatus, ISubmitFeedback, ISubmitHrSummary, IViewReviewResponse } from './types';

export const getMyPendingReviewsAction = createAsyncThunk(
  'Review/getMyPendingReviews',
  async (payload: IFilters, { rejectWithValue }) => {
    try {
      const response = await ReviewService.getMyPendingReviews(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getReviewByIdAction = createAsyncThunk('Review/getReviewById', async (id: number, { rejectWithValue }) => {
  try {
    const response = await ReviewService.getReviewById(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const submitFeedbackAction = createAsyncThunk(
  'Review/submitFeedback',
  async (payload: ISubmitFeedback, { rejectWithValue }) => {
    try {
      await ReviewService.submitFeedback(payload);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const submitHrSummaryAction = createAsyncThunk(
  'Review/submitHrSummary',
  async (payload: ISubmitHrSummary, { rejectWithValue }) => {
    try {
      await ReviewService.submitHrSummary(payload);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getReviewCountOverviewAction = createAsyncThunk(
  'Review/getReviewCountOverview',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ReviewService.getReviewCountOverview();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateReviewStatusAction = createAsyncThunk(
  'Review/updateReviewStatus',
  async (payload: IReviewStatus, { rejectWithValue }) => {
    try {
      const response = await ReviewService.updateReviewStatus(payload);
      return response.data as IViewReviewResponse;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Form, Typography } from 'antd';
import dayjs from 'dayjs';

import { useMobileContext, useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { setKeyValue } from '../../../../redux/slices/colleaguesReviewSlice';
import {
  getAllowedAssigneesAction,
  getAllowedAssigneesReviewersAction,
} from '../../../../redux/slices/colleaguesReviewSlice/actions';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import DateRangePicker from '../../../DateRangePicker';
import Divider from '../../../Divider';
import './ColleaguesReviewsGeneralData.less';
import { EmployeeSelect } from './EmployeeSelect';

type date = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

export const ColleaguesReviewsGeneralData = ({ form }) => {
  const { deviceHeight } = useMobileContext();
  const { theme } = useThemeContext();
  const dispatch = useAppDispatch();
  const { employeesList, reviewersList, generalData } = useAppSelector((state) => state.colleaguesReview);
  const intl = useIntl();

  useEffect(() => {
    dispatch(getAllowedAssigneesAction());
  }, []);

  useEffect(() => {
    if (generalData.subjectEmployee) {
      dispatch(getAllowedAssigneesReviewersAction(generalData.subjectEmployee));
    }
  }, [generalData.subjectEmployee]);

  const isSubjectEmployee = !generalData?.subjectEmployee;

  const handleDateChange = (dates: date) => {
    if (!dates || !dates[0] || !dates[1]) {
      dispatch(
        setKeyValue({
          key: 'generalData',
          value: {
            startDate: '',
            endDate: '',
          },
        })
      );
      return;
    }

    dispatch(
      setKeyValue({
        key: 'generalData',
        value: {
          startDate: dates[0].format('YYYY-MM-DD'),
          endDate: dates[1].format('YYYY-MM-DD'),
        },
      })
    );
  };

  return (
    <>
      <Form
        form={form}
        className={`colleagues-reviews-general-data widget-card ${theme}`}
        style={{ height: `calc(${deviceHeight}px - 300px)` }}
      >
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
          <Typography.Title level={4}>
            {intl.formatMessage({ id: 'ProvideWithGeneralDataForThisReview' })}
          </Typography.Title>
          <Divider spacing={16} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
          <Form.Item
            name='subjectEmployee'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'SubjectEmployeeSelectValidation' }),
              },
            ]}
          >
            <EmployeeSelect
              title={intl.formatMessage({ id: 'subjectEmployee' })}
              employeesList={employeesList}
              onChange={(value) => {
                dispatch(setKeyValue({ key: 'generalData', value: { subjectEmployee: value || null, reviewers: [] } }));
              }}
              value={generalData.subjectEmployee}
            />
          </Form.Item>
          <Form.Item
            name='reviewEmployees'
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'ReviewsEmployeesSelectValidation' }),
              },
            ]}
          >
            <EmployeeSelect
              title={intl.formatMessage({ id: 'reviewers' })}
              employeesList={reviewersList}
              multiple={true}
              disabled={isSubjectEmployee}
              onChange={(value) => dispatch(setKeyValue({ key: 'generalData', value: { reviewers: value } }))}
              value={generalData.reviewers}
            />
          </Form.Item>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className='typography-title-body'>{intl.formatMessage({ id: 'timeReview' })}</Typography>
            <Form.Item
              name='dates'
              rules={[
                {
                  required: true,
                  validator(_, value) {
                    if (!value || !value[0] || !value[1]) {
                      return Promise.reject(new Error(intl.formatMessage({ id: 'TimeReviewValidation' })));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DateRangePicker
                style={{ maxWidth: '400px', width: '100%' }}
                allowClear
                inputReadOnly
                format='DD/MM/YYYY'
                disabledDate={(current: dayjs.Dayjs) => {
                  return current && current < dayjs().startOf('day');
                }}
                onChange={handleDateChange}
                value={[
                  generalData.startDate ? dayjs(generalData.startDate) : null,
                  generalData.endDate ? dayjs(generalData.endDate) : null,
                ]}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  );
};

export const ArrowBottom = (props) => (
  <span role='img' aria-label='wfh' className='anticon anticon-wfh' {...props}>
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ verticalAlign: 'bottom' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2612 13.1967C10.6692 13.6011 11.3308 13.6011 11.7388 13.1967L14.694 10.2678C15.102 9.86337 15.102 9.20772 14.694 8.8033C14.2859 8.39889 13.6244 8.39891 13.2164 8.8033L11 11L8.78361 8.8033C8.37557 8.39889 7.71404 8.39891 7.30602 8.8033C6.898 9.2077 6.89798 9.86335 7.30602 10.2678L10.2612 13.1967Z'
        fill='currentColor'
      ></path>
    </svg>
  </span>
);

import { useIntl } from 'react-intl';

import { Form, Input, Row, Typography } from 'antd';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import { useAppSelector } from '../../../../redux/store';
import Divider from '../../../Divider';

export const ViewReviewGoalsAndKPIs = ({ maxRows }) => {
  const { theme } = useThemeContext();
  const intl = useIntl();
  const { deviceHeight } = useMobileContext();
  const { viewReview } = useAppSelector((state) => state.personalReview);
  const { goalsAndObjectives } = viewReview;

  return (
    <Row className='colleagues-review-goals widget-card' style={{ height: `calc(${deviceHeight}px - 300px)` }}>
      <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
        <Typography.Title level={4}>{intl.formatMessage({ id: 'GoalsAndKPIsForReview' })}</Typography.Title>
        <Divider spacing={16} />
      </div>
      <Form className='colleagues-review-goals-form '>
        <Input.TextArea
          className={`input-textarea ${theme}`}
          style={{ cursor: 'default' }}
          autoSize={{ minRows: maxRows, maxRows: maxRows }}
          value={goalsAndObjectives}
          disabled
        />
      </Form>
    </Row>
  );
};

export const CreateReviewLightIcon = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5723 4.86374L12.5723 2.405C12.5723 2.03237 12.4255 1.675 12.1642 1.41151C11.9029 1.14803 11.5485 1 11.179 1L2.81926 0.999999C2.44973 0.999999 2.09534 1.14802 1.83405 1.41151C1.57276 1.675 1.42597 2.03237 1.42597 2.405L1.42597 4.86374M6.99834 13L6.99834 5.03836M6.99834 5.03836L3.81368 8.08047M6.99834 5.03836L10.183 8.08047'
        stroke='#fff'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

import { useEffect, useState } from 'react';

interface IDynamicRow {
  deviceHeight: number;
  offsetHeight: number;
  minRows: number;
}

export const useDynamicRows = ({ deviceHeight, offsetHeight, minRows }: IDynamicRow) => {
  const [maxRows, setMaxRows] = useState<number>(minRows);

  // This hook calculates the maximum number of rows that can be displayed in a textarea based on the device height and the offset height.
  useEffect(() => {
    const availableHeight = deviceHeight - offsetHeight;
    const lineHeight = 24;
    const calculatedRows = Math.floor(availableHeight / lineHeight);
    setMaxRows(calculatedRows);
  }, [deviceHeight, offsetHeight, minRows]);

  return maxRows;
};

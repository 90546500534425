import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Avatar, Col, Select, Typography } from 'antd';
import { IEmployee } from 'services/providers/EmployeeProvider/types';

import { useThemeContext } from '../../../../../AppProvider/ConfigProviderSettings';
import { ArrowUnion } from '../../../../../Icons/General/arrowUnion';
import './EmployeeSelect.less';

interface IEmployeeSelect {
  title: string;
  employeesList: IEmployee[];
  multiple?: boolean;
  onChange?: (value: number | number[]) => void;
  open?: boolean;
  disabled?: boolean;
  onDropdownVisibleChange?: (open: boolean) => void;
  value: number | number[];
}

export const EmployeeSelect = ({
  title,
  employeesList,
  multiple = false,
  onChange,
  open,
  disabled = false,
  onDropdownVisibleChange,
  value,
}: IEmployeeSelect) => {
  const { theme } = useThemeContext();
  const intl = useIntl();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleDropdownVisibleChange = (open: boolean) => {
    setIsDropdownOpen(open);
    onDropdownVisibleChange?.(open);
  };

  const filterOption = (input, option) => {
    const employee = employeesList.find((emp) => emp.employeeId === option?.value);
    if (!employee) return false;
    return `${employee.lastName} ${employee.firstName}`.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Col>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography className='typography-title-body'>{title}</Typography>
        <div className={'select-options'} style={{ display: 'flex' }}>
          <Select
            className={`employee-select ${theme}`}
            popupClassName={`employee-select-popup ${theme}`}
            allowClear
            showSearch
            showArrow
            open={open}
            mode={multiple ? 'multiple' : undefined}
            filterOption={(input, option) => filterOption(input, option)}
            onChange={onChange}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            disabled={disabled}
            value={value}
            suffixIcon={<ArrowUnion className={`arrow_union ${isDropdownOpen ? 'rotate' : ''}`} />}
            style={{ width: '100%' }}
          >
            {employeesList.map((employee) => (
              <Select.Option key={employee.employeeId} value={employee.employeeId}>
                <div className='dropdown-options'>
                  <div style={{ display: 'flex' }}>
                    <Avatar src={employee.externalAvatarUrl} />
                  </div>
                  <div>
                    <Typography.Paragraph>
                      {employee.lastName} {employee.firstName}
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                      {intl.formatMessage({ id: employee.position })}
                      {Object.values(employee.teams).length > 0 &&
                        ` ${intl.formatMessage({ id: 'in' })} ${Object.values(employee.teams).join(', ')}`}
                    </Typography.Paragraph>
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </Col>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IAnswer, ICustomQuestion, IReviewTemplateResponse } from '../../../services/providers/ReviewProvider/types';
import { IGetReviewsResponse } from '../personalReviewSlice/types';
import { IFilters } from '../personalReviewSlice/types';
import {
  addReviewAction,
  getAllReviewsAction,
  getAllowedAssigneesAction,
  getAllowedAssigneesReviewersAction,
  getReviewTemplatesAction,
} from './actions';
import { IEmployee, IGeneralData, IGeneralSettings, IToolbarReview } from './types';

interface IColleaguesReviewState {
  toolbarReview: IToolbarReview;
  allReviews: IGetReviewsResponse[] | [];
  tabs: string[];
  activeTab: string;
  tableTabs: string[];
  activeTableTab: string;
  reviewTemplates: IReviewTemplateResponse[] | [];
  currentTemplateIndex: number;
  customQuestions: ICustomQuestion[];
  employeesList: IEmployee[] | [];
  reviewersList: IEmployee[] | [];
  textGoalsAndKPIs: string;
  generalData: IGeneralData;
  generalSettings: IGeneralSettings;
  pagination: Omit<IFilters['pagination'], 'lastDate' | 'lastId'>;
  filterBy: IFilters['filterBy'];
  sortBy: IFilters['sortBy'];
  loading: boolean;
  error: boolean | null;
}

const initialState: IColleaguesReviewState = {
  toolbarReview: {
    create: false,
    back: false,
    saveAndStart: false,
  },
  allReviews: [],
  tabs: ['generalData', 'review', 'generalSettings', 'goalsAndKPIs'],
  activeTab: 'generalData',
  tableTabs: ['All', 'AwaitingEmployeeFeedback', 'Active', 'Scheduled', 'PastReviews'],
  activeTableTab: 'All',
  reviewTemplates: [],
  currentTemplateIndex: 0,
  customQuestions: [],
  employeesList: [],
  reviewersList: [],
  textGoalsAndKPIs: '',
  generalData: {
    subjectEmployee: null,
    reviewers: [],
    startDate: '',
    endDate: '',
  },
  generalSettings: {
    notifySubject: true,
    allowFinishWithoutReviewersFeedback: true,
    isReviewersFeedbackVisibleToSubject: false,
    isFeedbackVisibleToAllMembers: false,
    isHrSummaryVisibleToSubject: true,
    isHrSummaryVisibleToReviewers: false,
    enableGoalsAndObjectives: false,
    isSelfAssessmentEnabled: false,
    isReviewHiddenToSubject: false,
  },
  pagination: {
    pageNumber: 1,
    resultSize: 20,
  },
  filterBy: {
    statuses: [],
    subjectName: '',
    subjectPositions: [],
    types: [],
  },
  sortBy: {
    reviewNumber: null,
    startDate: null,
    endDate: null,
  },
  loading: false,
  error: null,
};

export const initialGeneralData: IGeneralData = initialState.generalData;
export const initialGeneralSettings: IGeneralSettings = initialState.generalSettings;

const colleaguesReviewSlice = createSlice({
  name: 'colleaguesReview',
  initialState,
  reducers: {
    setKeyValue<T>(state, action: PayloadAction<{ key: string; value: T }>) {
      const { key, value } = action.payload;

      if (key === 'generalData' || key === 'generalSettings') {
        return { ...state, [key]: { ...state[key], ...value } };
      }

      return { ...state, [key]: value };
    },
    setToolbarReview(state, action: PayloadAction<IToolbarReview>) {
      return { ...state, toolbarReview: action.payload };
    },
    setCurrentTemplateIndex(state, action: PayloadAction<number>) {
      return { ...state, currentTemplateIndex: action.payload };
    },
    setCustomQuestions(state, action: PayloadAction<ICustomQuestion>) {
      const { text, notes } = action.payload;
      state.customQuestions.push({ text: text, notes: notes });
    },
    updateCustomQuestions(state, action: PayloadAction<{ index: number; question: string }>) {
      const { index, question } = action.payload;
      if (index >= 0 && index < state.customQuestions.length) {
        state.customQuestions[index].text = question;
      }

      return state;
    },
    deleteCustomQuestion(state, action: PayloadAction<number>) {
      const index = action.payload;
      if (index >= 0 && index < state.customQuestions.length) {
        state.customQuestions.splice(index, 1);
      }
    },
    resetState() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReviewTemplatesAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReviewTemplatesAction.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reviewTemplates = payload;
    });
    builder.addCase(getReviewTemplatesAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getAllowedAssigneesAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllowedAssigneesAction.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.employeesList = payload;
    });
    builder.addCase(getAllowedAssigneesAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getAllowedAssigneesReviewersAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllowedAssigneesReviewersAction.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reviewersList = payload;
    });
    builder.addCase(getAllowedAssigneesReviewersAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(addReviewAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addReviewAction.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addReviewAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getAllReviewsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllReviewsAction.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allReviews = payload;
    });
    builder.addCase(getAllReviewsAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const {
  setKeyValue,
  setToolbarReview,
  setCurrentTemplateIndex,
  setCustomQuestions,
  updateCustomQuestions,
  deleteCustomQuestion,
  resetState,
} = colleaguesReviewSlice.actions;
export default colleaguesReviewSlice.reducer;

import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useMobileContext, useThemeContext } from '../../../AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from '../../../components/Buttons';
import { ProfileUser } from '../../../components/Employee/EmployeeProfileCard/ProfileUserImage';
import ModalComponent from '../../../components/Modal';
import { useModalContext } from '../../../components/Modal/ModalProvider';
import { PersonalReviewsTable } from '../../../components/ReviewManagement';
import { WidgetOverview } from '../../../components/ReviewManagement/PersonalReviews/WidgetOverview';
import { WidgetComponent } from '../../../components/Widgets';
import useApiRequest from '../../../hooks/useApiRequest.hook';
import { setKeyValue } from '../../../redux/slices/colleaguesReviewSlice';
import { resetState } from '../../../redux/slices/colleaguesReviewSlice';
import { getMyPendingReviewsAction } from '../../../redux/slices/personalReviewSlice/actions';
import { getReviewCountOverviewAction } from '../../../redux/slices/personalReviewSlice/actions';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { EmployeeServices } from '../../../services';
import { IEmployee } from '../../../services/providers/EmployeeProvider/types';
import EditProfilePage from '../../Profile/EditProfilePage';
import { IOpenModalDefault } from './types';

const openModalDefault: IOpenModalDefault = {
  editProfile: false,
};

export const PersonalReviews = () => {
  const { isMobile } = useMobileContext();
  const { theme } = useThemeContext();
  const { toggleModal } = useModalContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { countOverview, pagination } = useAppSelector((state) => state.personalReview);
  const { activeTableTab } = useAppSelector((state) => state.colleaguesReview);

  const [openModal, setOpenModal] = useState(openModalDefault);

  const intl = useIntl();
  const [searchParams] = useSearchParams();

  const employeeId = searchParams.get('employeeId') ?? 1;

  const { data } = useApiRequest<IEmployee>(
    EmployeeServices.getEmployeeProfile,
    'false',
    {},
    {},
    null,
    [],
    false,
    true
  );

  useEffect(() => {
    dispatch(getReviewCountOverviewAction());
    dispatch(
      getMyPendingReviewsAction({
        pagination: {
          pageNumber: 1,
          resultSize: pagination.resultSize,
        },
      })
    );
    dispatch(resetState());
  }, []);

  const cancelBtnText = intl.formatMessage({ id: 'cancel' });
  const saveBtnText = intl.formatMessage({ id: 'save' });

  const ButtonCustom = BaseButton(ButtonSimple);

  const openEditProfile = () => {
    setOpenModal({ ...openModal, editProfile: true });
    toggleModal();
  };

  return (
    <>
      {/*Desktop Profile*/}
      {openModal.editProfile && (
        <ModalComponent
          isMaskClosable={false}
          modalKey='editEmployeeModal'
          formKeyId='employeeForm'
          title={<FormattedMessage id='EditEmployeeModalTitle' />}
          displayFooter
          submitBtnText={saveBtnText}
          cancelBtnText={cancelBtnText}
          children={<EditProfilePage scope={'editMyProfile'} employeeId={employeeId} />}
        />
      )}
      <div className='content-profile'>
        <div className='row_class_conatiner '>
          <div className='column_class_conatiner' style={{ maxWidth: 'calc(50% - 8px)' }}>
            <div className='row_class' style={{ minHeight: 'calc(50% - 8px)' }}>
              <div className={`border-custom ${theme} gutter-row profile-widget height-full `}>
                <WidgetComponent
                  bordered={false}
                  className={`card-widget  wrapper-profile-col ${theme} full-content`}
                  cover={<ProfileUser data={data} scope={'MyProfile'} employeeId={employeeId} />}
                  actions={[
                    <ButtonCustom
                      className={`text-bold-normal btn-primary-custom ${theme}`}
                      type='primary'
                      onClick={openEditProfile}
                    >
                      <FormattedMessage id='editProfileText' />
                    </ButtonCustom>,
                  ]}
                />
              </div>
            </div>
            <div className='grid-row gap'>
              <div className={`grid-col ${theme} `} style={{ width: '50%' }}>
                <WidgetOverview
                  count={countOverview?.awaitingEmployeeFeedback}
                  description={intl.formatMessage({ id: 'awaitingYourFeedback' })}
                  onClick={() => {
                    navigate('colleaguesReviews');
                    if (activeTableTab !== 'AwaitingEmployeeFeedback')
                      dispatch(setKeyValue({ key: 'activeTableTab', value: 'AwaitingEmployeeFeedback' }));
                  }}
                />
              </div>
              <div className={`grid-col ${theme} `} style={{ width: '50%' }}>
                <WidgetOverview
                  count={countOverview?.active}
                  description={intl.formatMessage({ id: 'activeReviews' })}
                  onClick={() => {
                    navigate('colleaguesReviews');
                    if (activeTableTab !== 'Active') dispatch(setKeyValue({ key: 'activeTableTab', value: 'Active' }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className='column_class_conatiner' style={{ maxWidth: 'calc(50% - 8px)' }}>
            <div className='row_class' style={{ minHeight: 'calc(50% - 8px)', zIndex: 2 }}>
              <div className={`border-custom ${theme} gutter-row profile-widget height-full `}>
                <WidgetComponent
                  bordered={false}
                  className={`card-widget  wrapper-profile-col ${theme} full-content`}
                  cover={<PersonalReviewsTable />}
                />
              </div>
            </div>
            <div className='grid-row gap'>
              <div className={`grid-col ${theme} `} style={{ width: '50%' }}>
                <WidgetOverview
                  count={countOverview?.scheduled}
                  description={intl.formatMessage({ id: 'scheduledReviews' })}
                  onClick={() => {
                    navigate('colleaguesReviews');
                    if (activeTableTab !== 'Scheduled')
                      dispatch(setKeyValue({ key: 'activeTableTab', value: 'Scheduled' }));
                  }}
                />
              </div>
              <div className={`grid-col ${theme} `} style={{ width: '50%', zIndex: 1 }}>
                <WidgetOverview
                  count={countOverview?.all}
                  description={intl.formatMessage({ id: 'totalReviews' })}
                  onClick={() => {
                    navigate('colleaguesReviews');
                    if (activeTableTab !== 'All') dispatch(setKeyValue({ key: 'activeTableTab', value: 'All' }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//import all providers here
import EmployeeDocumentProvider from 'services/providers/EmployeeDocumentProvider';
import EmployeeProvider from 'services/providers/EmployeeProvider';
import GeneralProvider from 'services/providers/GeneralProvider';
import AssetsProvider from 'services/providers/InventoryProvider/Assets';
import AssetCategoryProvider from 'services/providers/InventoryProvider/Category';
import TeamsProvider from 'services/providers/TeamsProvider';
import TimeOffProvider from 'services/providers/TimeOffProvider';

import AuthProvider from './providers/AuthProvider/AuthProvider';
import ClockInOutProvider from './providers/ClockInOutProvider';
import CompanySettingsProvider from './providers/CompanySettingsProvider';
import EventProvider from './providers/EventProvider';
import IssueProvider from './providers/IssueCollector';
import NotificationsProvider from './providers/NotificationsProvider';
import ReportProvider from './providers/ReportProvider';
import ReviewProvider from './providers/ReviewProvider';
import TicketingProvider from './providers/TicketingProvider';
import TicketingSettingsProvider from './providers/TicketingSettingsProvider';
import TicketingWorkQueueProvider from './providers/TicketingWorkQueueProvider';
import TimeSheetProvider from './providers/TimeSheetProvider';

//export all services here
export const TicketingWorkQueueService = new TicketingWorkQueueProvider();
export const EmployeeServices = new EmployeeProvider();
export const EmployeeDocumentService = new EmployeeDocumentProvider();
export const TeamsService = new TeamsProvider();
export const GeneralService = new GeneralProvider();
export const AssetsService = new AssetsProvider();
export const AssetCategoryService = new AssetCategoryProvider();
export const TimeOffService = new TimeOffProvider();
export const ClockInOutService = new ClockInOutProvider();
export const TimeSheetService = new TimeSheetProvider();
export const ReportService = new ReportProvider();
export const NotificationsService = new NotificationsProvider();
export const EventService = new EventProvider();
export const IssueService = new IssueProvider();
export const TicketingService = new TicketingProvider();
export const AuthService = new AuthProvider();
export const CompanySettingsService = new CompanySettingsProvider();
export const TicketingSettingsService = new TicketingSettingsProvider();
export const ReviewService = new ReviewProvider();

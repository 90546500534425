import { useIntl } from 'react-intl';

import { Row } from 'antd';

import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { setKeyValue } from '../../../../redux/slices/personalReviewSlice';
import { getMyPendingReviewsAction } from '../../../../redux/slices/personalReviewSlice/actions';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { TableLayoutPage } from '../../../LayoutPageWithTitle.tsx';
import TableComponent, { TableParams } from '../../../Table';
import { PersonalReviewsColumnTable } from '../PersonalReviewsColumnTable';
import './PersonalReviewsTable.less';

export const PersonalReviewsTable = () => {
  const { isMobile, deviceHeight } = useMobileContext();
  const { theme } = useThemeContext();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { pendingReviews, pagination } = useAppSelector((state) => state.personalReview);

  const tableComponentProps = {
    rowKey: 'reviewId',
    columns: PersonalReviewsColumnTable,
  };

  const handleTableOnChange = (params: TableParams) => {
    const { pagination, sorter } = params;
    const payload = {
      sortBy: {
        type: sorter?.['field'] === 'type' ? sorter?.['order'] : null,
        startDate: sorter?.['field'] === 'startDate' ? sorter?.['order'] : null,
        endDate: sorter?.['field'] === 'endDate' ? sorter?.['order'] : null,
      },
      pagination: {
        pageNumber: pagination.current,
        resultSize: pagination.pageSize,
      },
    };

    dispatch(setKeyValue({ key: 'pagination', value: payload.pagination }));
    dispatch(getMyPendingReviewsAction(payload));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setKeyValue({ key: 'pagination', value: { ...pagination, pageNumber } }));
  };

  return (
    <Row className='full-content' justify='space-around' style={{ width: '100%' }}>
      <TableLayoutPage
        title={intl.formatMessage({ id: 'YourReviewRequestedFor' })}
        className={'personal-reviews height-inherit'}
        style={{
          height: isMobile ? `calc(${deviceHeight}px - 122px)` : '',
        }}
      >
        <TableComponent
          scroll={{
            y: `${deviceHeight >= 880 ? `calc(${deviceHeight / 3.5}px)` : `calc(${deviceHeight / 3.7}px)`}`,
            x: 'calc(100vh - 39em)',
          }}
          tableSize='small'
          {...tableComponentProps}
          dataSource={pendingReviews?.responseData}
          data={pendingReviews?.responseData}
          totalItems={pendingReviews?.totalCount}
          pageSize={pagination?.resultSize}
          page={pagination?.pageNumber}
          setPage={(pageNumber) => handlePageChange(pageNumber)}
          className={`personal-reviews-table ${theme}`}
          onChange={handleTableOnChange}
        />
      </TableLayoutPage>
    </Row>
  );
};

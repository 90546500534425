import React, { FC, useEffect, useRef, useState } from 'react';

import { useMobileContext, useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { ArrowUnion } from 'Icons/General/arrowUnion';

import HeaderDropDownFilter from './HeaderDropDownFilter';
import PanelDropDownFilter from './PanelDropDownFilter';

interface MultipleDropDownFilterProps {
  dataForOptions: Record<string, any>;
  value: string[];
  onChangeFunction?: (value: string[]) => void;
  onChangeValue?: (value: string[]) => void;
  placeholder: string;
  textAllSelect?: Record<string, any>;
  instanceId: string;
  dropdownAlign?: { offset: [number, number] };
  preventDeselectAll?: boolean;
}

export const MultipleDropDownFilter: FC<MultipleDropDownFilterProps> = ({
  dataForOptions,
  value,
  onChangeFunction,
  onChangeValue,
  placeholder,
  textAllSelect,
  instanceId,
  dropdownAlign,
  preventDeselectAll,
}) => {
  const { theme } = useThemeContext();
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectAllOptions, setSelectAllOptions] = useState<string[]>([]);
  const { offset } = dropdownAlign || { offset: [0, 0] };
  const allOption = textAllSelect ? Object.keys(textAllSelect)[0] : null;
  const topValueInPixels = `${offset[0]}px`;
  const leftValueInPixels = `${offset[1]}px`;
  const { isMobile } = useMobileContext();
  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value && value.length === 1 && value.includes(allOption)) {
      const allOptionsAvailable = Object.keys(dataOptions);
      setSelectAllOptions(allOptionsAvailable);
      onChangeFunction(allOptionsAvailable);
    }
  }, [value]);

  const preselectedOptions = selectAllOptions.length > 0 ? selectAllOptions : value;

  const combineOptions = (keyOption: string, value: any) => {
    const selectAllOption = { [keyOption]: value };
    return { ...selectAllOption, ...dataForOptions };
  };

  const dataOptions = textAllSelect
    ? combineOptions(Object.keys(textAllSelect)[0], Object.values(textAllSelect)[0])
    : dataForOptions;

  const selectOption = (option: string) => {
    let updatedOptions: string[];

    if (option === allOption) {
      const allOptionsAvailable = Object.keys(dataOptions);
      if (preselectedOptions.includes(option)) {
        updatedOptions = [];
        setSelectAllOptions([]);
      } else {
        updatedOptions = allOptionsAvailable;
        setSelectAllOptions(allOptionsAvailable);
      }
    } else {
      if (preselectedOptions.includes(option)) {
        updatedOptions = preselectedOptions.filter((o) => o !== option && o !== allOption);
      } else {
        updatedOptions = [...preselectedOptions, option];
      }
      setSelectAllOptions(updatedOptions);
    }

    if (onChangeFunction) {
      onChangeFunction(updatedOptions);
    }

    if (onChangeValue) {
      const updatedValues = updatedOptions.map((key) => dataOptions[key]);
      onChangeValue(updatedValues);
    }
  };

  return (
    <div className={`container-filter ${theme}`} ref={containerRef} key={instanceId}>
      <div className={`dropdown-head ${expanded ? 'open' : ''}`} onClick={toggleExpanded}>
        <div className='dropdown-heading-value'>
          <HeaderDropDownFilter value={preselectedOptions} placeholder={placeholder} dataForOptions={dataOptions} />
        </div>
        <ArrowUnion className='arrow_filter' />
      </div>

      {expanded && (
        <div
          className='dropdown-content-body'
          style={{
            top: topValueInPixels,
            left: dropdownAlign.offset[1],
            right: dropdownAlign.offset[1] === null && isMobile ? '-3px' : null,
          }}
        >
          <div className='panel-content-list'>
            <PanelDropDownFilter
              dataForOptions={dataOptions}
              selectOption={selectOption}
              filtervalue={preselectedOptions}
              preventDeselectAll={preventDeselectAll ? preventDeselectAll : false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

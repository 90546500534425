import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AsyncThunkAction } from '@reduxjs/toolkit';
import { RootState, useAppDispatch } from 'redux/store';

import { useEffectWithSkipFirstMount } from './useEffectWithSkipFirstMount';

/**Helper hook that triggers re-execution of an action when methodUpdate slice is updated (usually on form submits).
 * Usually used in this project to update data on form submission
 */
export const useOnMethodUpdate = (action: AsyncThunkAction<any, any, any>) => {
  const dispatch = useAppDispatch();
  const methodUpdateSelector = useSelector((state: RootState) => state.methodUpdate); //trigger re-render when ticketing form is submitted and dispatches the "methodUpdate"
  useEffectWithSkipFirstMount(
    () => {
      if (methodUpdateSelector.isMethodUpdated === false) {
        dispatch(action);
      }
    },
    [methodUpdateSelector.isMethodUpdated],
    true
  );
};

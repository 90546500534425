import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Tabs } from 'antd';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import { setKeyValue } from '../../../../redux/slices/colleaguesReviewSlice';
import { getAllReviewsAction } from '../../../../redux/slices/colleaguesReviewSlice/actions';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { TableLayoutPage } from '../../../LayoutPageWithTitle.tsx';
import { useModalContext } from '../../../Modal/ModalProvider';
import TableComponent, { TableParams } from '../../../Table';
import { ColleaguesReviewsColumnTable } from '../ColleaguesReviewsColumnTable';
import './ColleaguesReviewsTable.less';

export const ColleaguesReviewsTable = () => {
  const { theme } = useThemeContext();
  const { isMobile } = useMobileContext();
  const { setIsModalOpen, setIsTopRoleModalOpen } = useModalContext();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { allReviews, tableTabs, activeTableTab, pagination, filterBy, sortBy } = useAppSelector(
    (state) => state.colleaguesReview
  );

  useEffect(() => {
    dispatch(
      getAllReviewsAction({
        filterBy: { filterType: activeTableTab === 'PastReviews' ? 'Finished' : activeTableTab },
        pagination: {
          pageNumber: pagination.pageNumber,
          resultSize: pagination.resultSize,
        },
      })
    );
    setIsModalOpen(false);
    setIsTopRoleModalOpen(false);
  }, []);

  const tabItems = tableTabs.map((key: string) => ({
    key,
    label: intl.formatMessage({ id: key }),
  }));

  const tableComponentProps = {
    rowKey: 'reviewId',
    columns: ColleaguesReviewsColumnTable,
  };

  const handleTableOnChange = (params: TableParams) => {
    const { filters, pagination, sorter } = params;

    const filterSubjectName = filters?.['subjectName'] ? String(filters['subjectName']) : null;

    const payload = {
      sortBy: {
        reviewNumber: sorter?.['field'] === 'reviewNumber' ? sorter?.['order'] : null,
        startDate: sorter?.['field'] === 'startDate' ? sorter?.['order'] : null,
        endDate: sorter?.['field'] === 'endDate' ? sorter?.['order'] : null,
      },
      filterBy: {
        filterType: activeTableTab === 'PastReviews' ? 'Finished' : activeTableTab,
        subjectName: filterSubjectName,
        subjectPositions: filterBy.subjectPositions,
        types: filterBy.types,
        statuses: filterBy.statuses,
      },
      pagination: {
        pageNumber: pagination.current,
        resultSize: pagination.pageSize,
      },
    };

    dispatch(setKeyValue({ key: 'filterBy', value: payload.filterBy }));
    dispatch(setKeyValue({ key: 'pagination', value: payload.pagination }));
    dispatch(setKeyValue({ key: 'sortBy', value: payload.sortBy }));
    dispatch(getAllReviewsAction(payload));
  };

  const handleTabChange = (key) => {
    const payload = {
      sortBy: {
        reviewNumber: sortBy.reviewNumber,
        startDate: sortBy.startDate,
        endDate: sortBy.endDate,
      },
      filterBy: {
        filterType: key === 'PastReviews' ? 'Finished' : key,
        subjectName: filterBy.subjectName,
        subjectPositions: filterBy.subjectPositions,
        types: filterBy.types,
        statuses: filterBy.statuses,
      },
      pagination: {
        pageNumber: 1,
        resultSize: 20,
      },
    };

    dispatch(setKeyValue({ key: 'activeTableTab', value: key }));
    dispatch(setKeyValue({ key: 'pagination', value: payload.pagination }));
    dispatch(setKeyValue({ key: 'sortBy', value: payload.sortBy }));
    dispatch(getAllReviewsAction(payload));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setKeyValue({ key: 'pagination', value: { ...pagination, pageNumber } }));
  };

  return (
    <>
      <TableLayoutPage className={'colleagues-reviews'} style={{ height: '100%' }}>
        <Tabs
          defaultActiveKey={activeTableTab}
          onChange={handleTabChange}
          className={`colleagues-reviews-tabs ${theme}`}
          items={tabItems}
        />
        <TableComponent
          scroll={
            isMobile
              ? {
                  x: 'calc(100vw - 213px)',
                }
              : {
                  x: '300px',
                  y: 'calc(100vh - 26em)',
                }
          }
          {...tableComponentProps}
          dataSource={allReviews?.responseData}
          data={allReviews?.responseData}
          pageSize={pagination?.resultSize}
          page={pagination?.pageNumber}
          setPage={(pageNumber) => handlePageChange(pageNumber)}
          totalItems={allReviews?.totalCount}
          onChange={handleTableOnChange}
          className={`colleagues-reviews-table ${theme}`}
        />
      </TableLayoutPage>
    </>
  );
};

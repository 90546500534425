import { useIntl } from 'react-intl';

import { setKeyValue } from '../../../../redux/slices/colleaguesReviewSlice';
import { getAllReviewsAction } from '../../../../redux/slices/colleaguesReviewSlice/actions';
import { ReviewStatus, ReviewType } from '../../../../redux/slices/personalReviewSlice/types';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { MultipleDropDownFilter } from '../../../UIComponents/MultipleDropDownFilter';

export const ColleaguesReviewsToolbarFilters = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { filterBy, activeTableTab, pagination, sortBy } = useAppSelector((state) => state.colleaguesReview);
  const { enums } = useAppSelector((state) => state.general);

  const positions = enums?.EmployeeFunction || {};

  const reviewType: ReviewType[] = ['PerformanceAndDevelopment', 'ProjectAndTaskSpecific', 'FeedbackAndEngagement', 
    'ComplianceAndConduct', 'CareerAndSuccessionPlanning', 'WorkplaceCultureAndFit'];

  const reviewStatus: ReviewStatus[] = ['Scheduled', 'Ongoing', 'UnderReview', 'Finished', 'Cancelled'];

  const handlePositionFilter = (selected: string[]) => {
    const payload = {
      sortBy: {
        reviewNumber: sortBy.reviewNumber,
        startDate: sortBy.startDate,
        endDate: sortBy.endDate,
      },
      filterBy: {
        filterType: activeTableTab === 'PastReviews' ? 'Finished' : activeTableTab,
        subjectPositions: selected,
        types: filterBy.types,
        statuses: filterBy.statuses,
      },
      pagination: {
        pageNumber: pagination.pageNumber,
        resultSize: pagination.resultSize,
      },
    };

    dispatch(setKeyValue({ key: 'filterBy', value: payload.filterBy }));
    dispatch(setKeyValue({ key: 'pagination', value: payload.pagination }));
    dispatch(setKeyValue({ key: 'sortBy', value: payload.sortBy }));
    dispatch(getAllReviewsAction(payload));
  };

  const handleTypeFilter = (selected: string[]) => {
    const payload = {
      sortBy: {
        reviewNumber: sortBy.reviewNumber,
        startDate: sortBy.startDate,
        endDate: sortBy.endDate,
      },
      filterBy: {
        filterType: activeTableTab === 'PastReviews' ? 'Finished' : activeTableTab,
        types: selected,
        subjectPositions: filterBy.subjectPositions,
        statuses: filterBy.statuses,
      },
      pagination: {
        pageNumber: pagination.pageNumber,
        resultSize: pagination.resultSize,
      },
    };

    dispatch(setKeyValue({ key: 'filterBy', value: payload.filterBy }));
    dispatch(setKeyValue({ key: 'pagination', value: payload.pagination }));
    dispatch(setKeyValue({ key: 'sortBy', value: payload.sortBy }));
    dispatch(getAllReviewsAction(payload));
  };

  const handleStatusFilter = (selected: string[]) => {
    const payload = {
      sortBy: {
        reviewNumber: sortBy.reviewNumber,
        startDate: sortBy.startDate,
        endDate: sortBy.endDate,
      },
      filterBy: {
        filterType: activeTableTab === 'PastReviews' ? 'Finished' : activeTableTab,
        statuses: selected,
        subjectPositions: filterBy.subjectPositions,
        types: filterBy.types,
      },
      pagination: {
        pageNumber: pagination.pageNumber,
        resultSize: pagination.resultSize,
      },
    };

    dispatch(setKeyValue({ key: 'filterBy', value: payload.filterBy }));
    dispatch(setKeyValue({ key: 'pagination', value: payload.pagination }));
    dispatch(setKeyValue({ key: 'sortBy', value: payload.sortBy }));
    dispatch(getAllReviewsAction(payload));
  };

  return (
    <div className='review-filters'>
      <MultipleDropDownFilter
        placeholder={`${intl.formatMessage({ id: 'Function' })}`}
        dataForOptions={positions}
        dropdownAlign={{ offset: [53, 0] }}
        value={[]}
        onChangeValue={(selected) => handlePositionFilter(selected)}
        instanceId='Position'
      />
      <MultipleDropDownFilter
        placeholder={`${intl.formatMessage({ id: 'Type' })}`}
        dataForOptions={reviewType}
        dropdownAlign={{ offset: [53, 0] }}
        value={[]}
        onChangeValue={(selected) => handleTypeFilter(selected)}
        instanceId='Type'
      />
      <MultipleDropDownFilter
        placeholder={`${intl.formatMessage({ id: 'StatusReview' })}`}
        dataForOptions={reviewStatus}
        dropdownAlign={{ offset: [53, 0] }}
        value={[]}
        onChangeValue={(selected) => handleStatusFilter(selected)}
        instanceId='Status'
      />
    </div>
  );
};

import { Fragment } from 'react';
import { useIntl } from 'react-intl';

import { Col, Row, Typography } from 'antd';

import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import { IReviewAnswer } from '../../../../redux/slices/personalReviewSlice/types';
import { useAppSelector } from '../../../../redux/store';
import Divider from '../../../Divider';
import { QuestionsFeedback } from '../QuestionsFeedback';

export const ViewReviewAfterSubmit = () => {
  const { deviceHeight } = useMobileContext();
  const intl = useIntl();

  const employeeId = useAppSelector((state) => state.user?.loggedUser?.employeeId);
  const { viewReview } = useAppSelector((state) => state.personalReview);

  const { reviewersFeedback, selfAssessment, questions } = viewReview;

  const reviewerFeedback = reviewersFeedback || [];

  /* Find the feedback provided by the logged-in user (if available) */
  const reviewFeedback = reviewerFeedback.find((review) => review.owner.employeeId === employeeId);
  /* After submitting a review, the user can access what they previously submitted */
  const reviewFeedbackAnswers = reviewFeedback?.answers || selfAssessment?.answers;

  return (
    <Row className={`view-feedback-review-template widget-card`} style={{ height: `calc(${deviceHeight}px - 300px)` }}>
      <Col style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Col style={{ paddingLeft: '1em', paddingRight: '1em' }}>
          <Typography.Title level={4}>{intl.formatMessage({ id: 'SummaryOfYourFeedback' })}</Typography.Title>
          <Divider spacing={16} />
        </Col>
        <Col className={'review-template'}>
          {reviewFeedbackAnswers.length === 0 && (
            <Col className='empty-text'>
              <div>
                <Typography
                  className={`typography-title-body`}
                  style={{ textAlign: 'center', fontWeight: 400, fontSize: '1rem' }}
                >
                  {intl.formatMessage({ id: 'NothingToBeDisplayedHere' })}
                </Typography>
                <Divider spacing={16} />
              </div>
            </Col>
          )}

          {reviewFeedbackAnswers?.map((answer: IReviewAnswer, id: number) => {
            const question = questions.find((q) => q.id === answer?.reviewQuestionId);

            return (
              <Fragment key={id}>
                <QuestionsFeedback answerId={id} answer={{ ...answer, reviewQuestion: question }} />
              </Fragment>
            );
          })}
        </Col>
      </Col>
    </Row>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { DateToBeginningOfLocalDate, convertToUTC } from 'components/DateParser';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { RootState } from 'redux/store';
import { EventService } from 'services';

import { useTranslationExistenceChecker } from '../../../components/UIComponents/SafeFormattedMessage';
import {
  EventDefinitions,
  EventDefinitionsHardOpacity,
  EventDefinitionsLowOpacity,
  EventTimeFormat,
  EventTypes,
  EventsViewType,
} from '../types';
import { IEventDataModel } from './types';
import { isAllDayEvent } from './utils';

dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.extend(utc);

export const useCalendarEvents = (
  selectedDate: dayjs.Dayjs,
  eventTypes: any,
  eventsViewType: EventsViewType,
  calendarViewType
) => {
  const [status, setStatus] = useState<{
    calendarEvents: 'pending' | 'success';
    calendarDaysData: 'pending' | 'success';
  }>({
    calendarEvents: 'pending',
    calendarDaysData: 'pending',
  });
  const intl = useIntl();
  const [calendarDaysData, setCalendarDaysData] = useState({});
  const [calendarEvents, setCalendarEvents] = useState([]);
  const methodUpdateSelector = useSelector((state: RootState) => state.methodUpdate);

  const timeOffPerDayMap = useMemo(() => {
    const map = {};

    calendarEvents?.forEach((event) => {
      const startDate = dayjs(event?.startDate);
      const endDate = dayjs(event?.endDate);

      if (!event?.timeOffType) {
        return;
      }

      for (let day = startDate; day <= endDate; day = day.add(1, 'day')) {
        const currentDay = day.format('YYYY-MM-DD');

        if (!map[currentDay]) {
          map[currentDay] = 1;
        } else {
          map[currentDay] = map[currentDay] + 1;
        }
      }
    });

    return map;
  }, [calendarEvents]);

  const { theme } = useThemeContext();

  const currentDateOfRefference = useMemo(() => {
    switch (calendarViewType) {
      case 'dayGridMonth':
        return selectedDate.get('month');
      case 'timeGridWeek':
        return selectedDate.week();
      case 'timeGridDay':
        return selectedDate.get('day');
      default:
        return selectedDate.get('year');
    }
  }, [calendarViewType, selectedDate]);

  const getViewType = () =>
    calendarViewType === 'dayGridMonth'
      ? 'Month'
      : calendarViewType === 'timeGridWeek'
        ? 'Week'
        : calendarViewType === 'timeGridDay'
          ? 'Day'
          : 'Year';

  useEffect(() => {
    setCalendarDaysData(() =>
      calendarEvents.filter((event) => {
        const startDate = dayjs(event.startDate);
        const endDate = dayjs(event.endDate);

        return startDate.isValid() && endDate.isValid() && selectedDate.isBetween(startDate, endDate, 'days', '[]');
      })
    );
  }, [selectedDate, calendarEvents]);

  useEffect(() => {
    setStatus((state) => ({
      calendarEvents: 'pending',
      calendarDaysData: 'pending',
    }));

    EventService.getCalendarEvents({
      dateOnly: DateToBeginningOfLocalDate(selectedDate),
      eventTypes,
      eventsViewType: eventsViewType,
      calendarViewType: getViewType(),
    }).then((response) => {
      setCalendarEvents(() => {
        setStatus((state) => ({
          ...state,
          calendarEvents: 'success',
          calendarDaysData: 'success',
        }));
        return response.data;
      });
    });

    return () => {
      /* should consider implementing abort controller */
    };
  }, [eventsViewType, methodUpdateSelector?.isMethodUpdated, eventTypes, calendarViewType, currentDateOfRefference]);

  /**Helper function which aggregates events with TimeOffEvents !==null by periods and returns them as separate events to be displayed
   * in TimeOffEvent banners in calendar
   */
  //Adapt time complexity to O(n) and space complexity to linear-logarithmic where possible for slow devices- done
  const aggregatedVacations = useMemo<Array<any>>(() => {
    return Object.entries(timeOffPerDayMap).map(([key, value]: [any, any]) => {
      return {
        eventObj: {},
        title:
          value === 1
            ? `${value} ${intl.formatMessage({
                id: 'TimeOffEvent',
              })}`
            : `${value} ${intl.formatMessage({
                id: 'TimeOffEvents',
              })}`,

        start: key,
        end: key, //adding one day since FC events model doesn't extend the banner on the end date
        color: EventDefinitionsHardOpacity.TimeOffEvent.badgeColor,
        borderColor: 'transparent',
        display: 'block',
        allDay: true,
        isTimeOffEvent: true,
        className: 'event-TimeOffEvent',
        // durationDays: dayjs(value.period[1]).diff(
        //   dayjs(period.period[0]),
        //   'days'
        // ),
        eventOverlap: false,
      };
    });
  }, [calendarEvents, intl, timeOffPerDayMap, theme]);
  const translationChecker = useTranslationExistenceChecker();
  const defaultCalendarEvents = useMemo<Array<IEventDataModel>>(() => {
    return calendarEvents?.map((event): IEventDataModel => {
      if (!event.timeOffType) {
        const startDate = dayjs(event?.startDate);
        const endDate = dayjs(event?.endDate);
        const durationDays = dayjs(event?.endDate).diff(dayjs(event?.startDate), 'days');
        const titleRender = () => {
          //for birthday system events, separate handling is required
          if (event?.eventName.split('_').includes('BirthDate')) {
            return intl.formatMessage(
              { id: 'BirthDate' },
              {
                firstName: event?.eventName.split('_')[0],
                lastName: event?.eventName.split('_')[1],
              }
            );
          } else
            return translationChecker.doesTranslationExist({
              id: event?.eventName,
            });
        };

        return {
          eventObj: event,
          title: titleRender(),

          start:
            // disable conversion of date for events allday
            // isAllDayEvent(event)? convertToUTC(startDate).toDate() :
            event?.startDate ? startDate.toDate() : null,
          end: isAllDayEvent(event)
            ? convertToUTC(endDate).toDate()
            : event?.endDate
              ? endDate
                  .add(event.timeOffType ? 1 : 0, 'days') //adding one day since FullCalendar's end of events is based on the end of next day
                  .toDate()
              : null,

          color:
            calendarViewType === 'dayGridMonth'
              ? EventDefinitions[event?.eventType].badgeColor
              : theme === 'light'
                ? EventDefinitionsHardOpacity[event?.eventType].badgeColor
                : EventDefinitionsLowOpacity[event?.eventType].badgeColor,
          display: event?.timeOffType ? 'block' : 'list-item',
          allDay:
            event?.timeOffType !== null ||
            event?.eventType === EventTypes.Holiday ||
            event?.timeFormat === EventTimeFormat.AllDay ||
            (event?.timeFormat as string) === 'None', //default system generated events should be full day (birthdays etc)
          isTimeOffEvent: !!event?.timeOffType,
          className: `event-${event.eventType} 
            ${event.isExtendedViewAllowed ? null : 'not-clickable'}`,
          durationDays,
        };
      }
    });
  }, [calendarEvents, intl, theme]);

  return {
    eventsData:
      //map the data to adapt to the events model of FulLCalendar API
      [...defaultCalendarEvents, ...aggregatedVacations].filter((i) => i),

    //events per day
    calendarDaysData,
    status,
  };
};

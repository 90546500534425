import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { getFileType } from 'pages/Ticketing/utils';
import { getTicketById } from 'redux/slices/ticketingSlice/actions';
import { RootState, useAppDispatch } from 'redux/store';
import { TicketingService } from 'services';
import { download_files } from 'utils/downloadFiles';

import { LastCommentRefContext } from '../..';
import TicketAttachments from './components/TicketAttachments';
import TicketComment from './components/TicketComment';
import TicketHistory from './components/TicketHistory';
import styles from './ticketCommentSection.module.less';

const TicketCommentSection = (props) => {
  const [comments, setComments] = useState([]);
  const { selectedTicket } = useSelector((state: RootState) => state.ticketing);
  const dispatch = useAppDispatch();
  const parentRef = useRef(null);
  const sectionRef = useRef(null);
  //--------- handles scrolling to last comment whenever ticket's comments have changed (submitted new comment)
  const prevTicket = useRef(null);
  const lastCommentRef = useContext(LastCommentRefContext);
  const intl = useIntl();
  let commentIndex = -1; // Separate index for comments

  useEffect(() => {
    //whenever comment section unmounts, we store in ref the last ticket
    //this will be used further to understand if any change in comments happened due to changing selected ticket, or comment submit
    return () => (prevTicket.current = selectedTicket);
  });
  useEffect(() => {
    //whenever comments array length is changed, but the ticket is the same, this indicates an update in comments, therefore scroll to last one
    if (prevTicket?.current?.ticketId === selectedTicket?.ticketId) {
      (lastCommentRef as any)?.current?.showLastComment();
    }

    if (selectedTicket?.history && selectedTicket?.comments) {
      setComments(
        [...selectedTicket?.history, ...selectedTicket?.comments].sort((a, b) => {
          const dateA = a.createdOn ? dayjs(a.createdOn).unix() : dayjs(a.timeStamp).unix();
          const dateB = b.createdOn ? dayjs(b.createdOn).unix() : dayjs(b.timeStamp).unix();
          return dateA - dateB;
        })
      );
    }
  }, [selectedTicket?.ticketId, selectedTicket?.comments?.length, selectedTicket?.history?.length]);
  //---------

  return (
    <div className={styles.commentSectionContainer} ref={sectionRef}>
      {/* Category */}
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Typography.Title level={5} className={styles.sectionTitle}>
            <FormattedMessage id={'TicketingCommentSection_category'} />
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography className={styles.highlightedLabel}>
            {
              selectedTicket?.category &&
                intl.formatMessage({
                  id: selectedTicket?.category?.key,
                  defaultMessage: selectedTicket?.category?.name,
                })
              // <FormattedMessage
              //   id={selectedTicket?.category?.key as unknown as string}
              // />
            }
          </Typography>
        </Col>
      </Row>

      {/* Details */}
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Typography.Title level={5} className={styles.sectionTitle}>
            <FormattedMessage id={'TicketingCommentSection_details'} />
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text className={styles.detailsText}>{selectedTicket?.details}</Typography.Text>
        </Col>
      </Row>

      {/* Attachments */}
      {selectedTicket?.attachmentNames?.length > 0 && (
        <Row>
          <Col span={24}>
            <Typography.Title level={5} className={styles.sectionTitle} style={{ paddingBottom: '12px' }}>
              <FormattedMessage id={'TicketingCommentSection_attachments'} />
            </Typography.Title>
          </Col>
          <Col span={24} ref={parentRef}>
            <TicketAttachments
              perPage={parentRef?.current?.offsetWidth ? Math.round(parentRef?.current?.offsetWidth / 200) : 3}
              attachmentMaxWidth={120}
              attachmentFiles={selectedTicket?.attachmentNames?.map((attachment, fileOrder) => ({
                fileName: attachment,
                fileType: getFileType(attachment),
                onDownload: () => {
                  TicketingService.getTicketAttachmentUrl({
                    ticketId: selectedTicket.ticketId,
                    fileOrder: fileOrder,
                  }).then((response) =>
                    download_files([
                      {
                        link: response.data,
                        fileName: (response.data as string).split('/').at(-1).split('?sv=')[0],
                      },
                    ])
                  );
                },
                onRemove: () => {
                  TicketingService.deleteAttachment({
                    ticketId: selectedTicket.ticketId,
                    fileOrder: fileOrder,
                  }).then((response) => {
                    dispatch(getTicketById(selectedTicket.ticketId));
                  });
                },
              }))}
            />
          </Col>
        </Row>
      )}

      {/* Comments */}
      <Row>
        <Col span={24}>
          <Typography.Title level={5} className={styles.sectionTitle}>
            <FormattedMessage id={'TicketingCommentSection_comments'} />
          </Typography.Title>
        </Col>
        <Col span={24}>
          {comments.map((comment, index) => {
            if (comment?.commentId !== undefined) {
              commentIndex++; // Increment comment index for each comment

              return (
                <TicketComment
                  key={comment.commentId}
                  comment={{
                    ...comment,
                    groupByTime:
                      commentIndex !== 0 && selectedTicket?.comments[commentIndex - 1]
                        ? dayjs(comment?.createdOn).diff(
                            dayjs(selectedTicket?.comments[commentIndex - 1].createdOn),
                            'minute'
                          ) <= 1 // Number of minutes between the two dates
                        : false,
                  }}
                  isLastComment={index === comments.length - 1}
                  ref={index === comments.length - 1 ? lastCommentRef : undefined}
                />
              );
            } else {
              return <TicketHistory key={comment.ticketHistoryId} index={index} update={comment} history={comments} />;
            }
          })}
        </Col>
      </Row>
    </div>
  );
};
export default TicketCommentSection;

import { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { DownCircleOutlined } from 'Icons/downCircleArrow';
import { Avatar, Collapse, Tooltip, Typography } from 'antd';
import { IEmployee } from 'services/providers/EmployeeProvider/types';

import './index.less';

/**
 * Displays a list of avatars corresponding to employees.
 * When the avatars are clicked, a dropdown with more details(firstname, lastname) for each avatar is shown
 */
export const EmployeesAvatarDropdown: React.FC<{ data: IEmployee[] }> = memo(({ data }) => {
  const AVATAR_ITEM_WIDTH = 25;
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const avatarDropdownRef = useRef<HTMLDivElement>(null);
  const [avatarDropdownParentWidth, setAvatarDropdownParentWidth] = useState(0);
  const { theme } = useThemeContext();

  useLayoutEffect(() => {
    setAvatarDropdownParentWidth(avatarDropdownRef?.current?.offsetWidth);
  }, [avatarDropdownRef.current]);

  const users = useMemo(
    () =>
      data?.map((employee) => {
        return {
          avatarInitials: employee?.firstName[0] + employee?.lastName[0],
          firstName: employee?.firstName,
          lastName: employee?.lastName,
          avatarSrc: employee?.externalAvatarUrl,
        };
      }),
    [data]
  );

  const maxAvatarItemsDisplayCount = Math.floor(avatarDropdownParentWidth / AVATAR_ITEM_WIDTH);

  const avatarSpacingClassname =
    users?.length < maxAvatarItemsDisplayCount ? 'space-items-start' : 'space-items-evenly';

  return (
    <div
      className={`EmployeesAvatarDropdown ${isCollapseOpen ? 'open' : 'closed'} ${avatarSpacingClassname} ${theme}`}
      ref={avatarDropdownRef}
    >
      {users?.length ? (
        <Collapse
          ghost
          onChange={() => {
            setTimeout(
              () => {
                setIsCollapseOpen((prev) => !prev);
              },
              isCollapseOpen ? 150 : 0
            );
          }}
          expandIcon={() => isCollapseOpen && <DownCircleOutlined className='avatar-dropdown-down-circle' />}
          expandIconPosition='end'
        >
          <Collapse.Panel
            header={
              !isCollapseOpen && (
                <Avatar.Group size={AVATAR_ITEM_WIDTH} maxCount={maxAvatarItemsDisplayCount}>
                  {users?.map((employee, index) => {
                    return (
                      <Tooltip title={employee?.lastName + ' ' + employee?.firstName}>
                        <Avatar
                          draggable
                          src={employee?.avatarSrc}
                          className='avatar-item'
                          style={{
                            marginInlineStart: index === 0 ? '0' : '-4px',
                          }}
                        />
                      </Tooltip>
                    );
                  })}
                </Avatar.Group>
              )
            }
            key='1'
            style={{ maxHeight: 200, overflow: 'scroll' }}
          >
            {users?.map((employee, index) => {
              return (
                <div
                  key={employee?.lastName + index}
                  style={{ paddingBlockStart: 5 }}
                  className={'employeeAvatar-item'}
                >
                  <Avatar src={employee?.avatarSrc}></Avatar>{' '}
                  <Typography.Paragraph
                    key={employee?.lastName + index}
                    ellipsis={true}
                    style={{ paddingBlockStart: 5, maxWidth: '80%' }}
                    className={'employeeAvatar-item'}
                  >
                    <span className='text-ellipsis-fit-content'>
                      {employee.lastName} {employee.firstName}
                    </span>
                  </Typography.Paragraph>
                </div>
              );
            })}
          </Collapse.Panel>
        </Collapse>
      ) : (
        <FormattedMessage id='noMembersOnEventLabel' />
      )}
    </div>
  );
});

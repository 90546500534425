import { useEffect } from 'react';

import { useMobileContext, useThemeContext } from '../../../AppProvider/ConfigProviderSettings';
import { useDynamicRows } from '../../../hooks/useDynamicRows';
import { getReviewTemplatesAction } from '../../../redux/slices/colleaguesReviewSlice/actions';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { WidgetComponent } from '../../Widgets';
import { ColleaguesReviewGoalsAndKPIs } from './ColleaguesReviewGoalsAndKPIs';
import './ColleaguesReviews.less';
import { ColleaguesReviewsGeneralData } from './ColleaguesReviewsGeneralData';
import { ColleaguesReviewsGeneralSettings } from './ColleaguesReviewsGeneralSettings';
import { ColleaguesReviewsSummary } from './ColleaguesReviewsSummary';
import { ColleaguesReviewsTabBar } from './ColleaguesReviewsTabBar';
import { ColleaguesReviewsTable } from './ColleaguesReviewsTable';
import { ColleaguesReviewTemplate } from './ColleaguesReviewsTemplate';

export const ColleaguesReviewsContent = ({ form }) => {
  const { deviceHeight } = useMobileContext();
  const { theme } = useThemeContext();
  const dispatch = useAppDispatch();

  const { toolbarReview, activeTab } = useAppSelector((state) => state.colleaguesReview);
  const { create } = toolbarReview;

  useEffect(() => {
    if (create) {
      dispatch(getReviewTemplatesAction());
    }
  }, [create]);

  const maxRows = useDynamicRows({ deviceHeight, offsetHeight: 360, minRows: 20 });

  const tabComponents = {
    review: <ColleaguesReviewTemplate />,
    generalData: <ColleaguesReviewsGeneralData form={form} />,
    generalSettings: <ColleaguesReviewsGeneralSettings />,
    goalsAndKPIs: <ColleaguesReviewGoalsAndKPIs form={form} maxRows={maxRows} />,
  };

  return (
    <WidgetComponent
      style={{
        height: `calc(${deviceHeight}px - 186px)`,
        overflowY: 'hidden',
      }}
      bordered={false}
      className={`card-widget wrapper-profile-col ${theme} full-content`}
      cover={
        <>
          {create ? (
            <div className='colleagues-reviews-content'>
              <ColleaguesReviewsTabBar />
              <div className='colleagues-review-body'>
                {tabComponents[activeTab]}
                {activeTab in tabComponents && <ColleaguesReviewsSummary />}
              </div>
            </div>
          ) : (
            <ColleaguesReviewsTable />
          )}
        </>
      }
    />
  );
};

import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Typography } from 'antd';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { ArrowBottom } from '../../../../Icons/Documents/arrowBottom';
import { ArrowTop } from '../../../../Icons/Documents/arrowTop';
import { useResizeTruncation } from '../../../../hooks/useResizeTruncation';
import './ReviewNotes.less';

interface ReviewNotesProps {
  question: {
    id: number;
    notes: string;
  };
}

export const ReviewNotes = ({ question }: ReviewNotesProps) => {
  const { theme } = useThemeContext();

  const [expandedNotes, setExpandedNotes] = useState<Record<number, boolean>>({});
  const [ellipsed, setEllipsed] = useState(false);
  const paragraphRef = useRef<HTMLDivElement>(null);

  const intl = useIntl();

  useResizeTruncation({
    ref: paragraphRef,
    setEllipsed,
    deps: [expandedNotes, question, ellipsed],
  });

  const toggleNotes = (id: number) => {
    setExpandedNotes((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  return (
    <>
      {question?.notes && (
        <Col className='notes' style={{ display: expandedNotes[question.id] ? 'block' : 'flex', alignItems: 'center' }}>
          <Typography.Paragraph
            ref={paragraphRef}
            className={`typography-paragraph ${theme}`}
            ellipsis={!expandedNotes[question.id] ? { rows: 1, expandable: false } : false}
            style={{ display: expandedNotes[question.id] && 'inline' }}
          >
            <InfoCircleOutlined /> {'  '}
            {question?.notes}
          </Typography.Paragraph>
          {ellipsed && !expandedNotes[question.id] && (
            <>
              <div
                style={{ display: 'inline-flex', fontWeight: 700 }}
                className={`show ${theme}`}
                onClick={() => toggleNotes(question.id)}
              >
                {intl.formatMessage({ id: 'more' })}
                <ArrowBottom className={`arrow ${theme}`} />
              </div>
            </>
          )}
          {expandedNotes[question.id] && (
            <>
              <div
                style={{ display: 'inline-flex', marginLeft: '2px' }}
                className={`hide ${theme}`}
                onClick={() => toggleNotes(question.id)}
              >
                {intl.formatMessage({ id: 'less' })}
                <ArrowTop className={`arrow ${theme}`} />
              </div>
            </>
          )}
        </Col>
      )}
    </>
  );
};

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';

import { getColumnSearchProps } from '../../../TimeManagement/TimeOffRequest/TimeOffRequestTable/TimeOffRequestTableColumns/SearchTable';

export const ColleaguesReviewsColumnTable = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const tableColumns = [
    {
      title: `${intl.formatMessage({ id: 'No' })}.`,
      width: '40px',
      key: 'reviewNumber',
      dataIndex: 'reviewNumber',
      showSorterTooltip: false,
      sorter: true,
      render: (_, record) => {
        return (
          <Tooltip placement='top' title={`${record?.reviewNumber}`}>
            <Typography.Text className='text-medium-big pointer-link line-clamp-1'>
              {record?.reviewNumber}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: `${intl.formatMessage({ id: 'ColleagueName' })}`,
      width: '100px',
      key: 'subjectName',
      dataIndex: 'subjectName',
      filterSearch: true,
      ...getColumnSearchProps(),
      render: (_, record) => {
        return (
          <Tooltip placement='top' title={`${record.subject?.lastName} ${record.subject?.firstName}`}>
            <Typography.Text
              className='text-medium-big pointer-link line-clamp-1 text-ellipsis'
              underline
              onClick={() => {
                navigate(`/reviewManagement/colleaguesReviews/viewReview?reviewId=${record.reviewId}`);
              }}
            >
              {record.subject?.lastName} {record.subject?.firstName}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: `${intl.formatMessage({ id: 'Function' })}`,
      width: '100px',
      key: 'function',
      render: (_, record) => {
        return (
          <Tooltip placement='top' title={intl.formatMessage({ id: record.subject?.position })}>
            <Typography.Text className='text-medium-big pointer-link line-clamp-1 text-ellipsis'>
              {intl.formatMessage({ id: record.subject?.position })}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: `${intl.formatMessage({ id: 'teams' })}`,
      width: '100px',
      key: 'teams',
      render: (_, record) => {
        return (
          <Tooltip placement='topLeft' title={`${Object.values(record.subject?.teams || {}).join(', ')}`}>
            <Typography.Text className='text-medium-big pointer-link line-clamp-1 text-ellipsis'>
              {Object.values(record.subject?.teams || {}).join(', ')}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: `${intl.formatMessage({ id: 'Type' })}`,
      width: '100px',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <Tooltip placement='top' title={intl.formatMessage({ id: record.type })}>
          <Typography.Text className='text-medium-big pointer-link line-clamp-1 text-ellipsis'>
            {intl.formatMessage({ id: record.type })}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'startDate' })}`,
      width: '100px',
      dataIndex: 'startDate',
      key: 'startDate',
      showSorterTooltip: false,
      sorter: true,
      render: (_, record) => (
        <Tooltip placement='top' title={dayjs(record.startDate).format('DD/MM/YYYY')}>
          <Typography.Text className='text-medium-big pointer-link line-clamp-1'>
            {dayjs(record.startDate).format('DD/MM/YYYY')}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'endDate' })}`,
      width: '100px',
      dataIndex: 'endDate',
      key: 'endDate',
      showSorterTooltip: false,
      sorter: true,
      render: (_, record) => (
        <Tooltip placement='top' title={dayjs(record.endDate).format('DD/MM/YYYY')}>
          <Typography.Text className='text-medium-big pointer-link line-clamp-1'>
            {dayjs(record.endDate).format('DD/MM/YYYY')}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'StatusReview' })}`,
      width: '100px',
      key: 'status',
      render: (_, record) => (
        <Tooltip placement='top' title={intl.formatMessage({ id: record.status })}>
          <Typography.Text className='text-medium-big pointer-link line-clamp-1 text-ellipsis'>
            {intl.formatMessage({ id: record.status })}
          </Typography.Text>
        </Tooltip>
      ),
    },
  ];

  return [...tableColumns];
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICountOverview } from '../../../services/providers/ReviewProvider/types';
import {
  getMyPendingReviewsAction,
  getReviewByIdAction,
  getReviewCountOverviewAction,
  submitFeedbackAction,
  updateReviewStatusAction,
} from './actions';
import { Answer, IFilters, IGetReviewsResponse, IViewReviewResponse } from './types';

interface IPersonalReviewState {
  pendingReviews: IGetReviewsResponse[] | [];
  viewReview: IViewReviewResponse;
  activeReview: boolean;
  tabs: string[];
  activeTab: string;
  answers: Answer[];
  currentReviewIndex: number;
  hrSummaryInput: string;
  countOverview: ICountOverview | {};
  pagination: Omit<IFilters['pagination'], 'lastDate' | 'lastId'>;
  loading: boolean;
  error: boolean | null;
}

const initialState: IPersonalReviewState = {
  pendingReviews: [],
  viewReview: {
    hrSummary: '',
  } as IViewReviewResponse,
  activeReview: true,
  tabs: ['review', 'generalSettings', 'goalsAndKPIs', 'showReviewersFeedbackSummary', 'selfAssessment', 'hrSummary'],
  activeTab: 'review',
  answers: [],
  currentReviewIndex: 0,
  hrSummaryInput: '',
  countOverview: {},
  pagination: {
    pageNumber: 1,
    resultSize: 20,
  },
  loading: false,
  error: null,
};

const personalReviewSlice = createSlice({
  name: 'personalReview',
  initialState,
  reducers: {
    setKeyValue<T>(state, action: PayloadAction<{ key: string; value: T }>) {
      const { key, value } = action.payload;

      if (key === 'viewReview') {
        return { ...state, [key]: { ...state[key], ...value } };
      }

      return { ...state, [key]: value };
    },
    resetState() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyPendingReviewsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMyPendingReviewsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.pendingReviews = action.payload;
    });
    builder.addCase(getMyPendingReviewsAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getReviewByIdAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReviewByIdAction.fulfilled, (state, action) => {
      state.loading = false;
      state.viewReview = action.payload;
    });
    builder.addCase(getReviewByIdAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(submitFeedbackAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(submitFeedbackAction.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(submitFeedbackAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getReviewCountOverviewAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReviewCountOverviewAction.fulfilled, (state, action) => {
      state.loading = false;
      state.countOverview = action.payload;
    });
    builder.addCase(getReviewCountOverviewAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(updateReviewStatusAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateReviewStatusAction.fulfilled, (state, action) => {
      state.loading = false;
      state.viewReview = action.payload;
    });
    builder.addCase(updateReviewStatusAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { setKeyValue, resetState } = personalReviewSlice.actions;
export default personalReviewSlice.reducer;

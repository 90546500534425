import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Typography } from 'antd';
import isEqual from 'lodash/isEqual';

import { resetState, setKeyValue } from '../../../../redux/slices/personalReviewSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import ModalComponent from '../../../Modal';
import { useModalContext } from '../../../Modal/ModalProvider';
import SearchToolbarComponent from '../../../UIComponents/SearchToolbar';

export const ViewReviewToolBar = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { isModalOpen, setIsModalOpen } = useModalContext();

  const employeeId = useAppSelector((state) => state.user?.loggedUser?.employeeId);
  const { answers, hrSummaryInput } = useAppSelector((state) => state.personalReview);

  const isReviewPage = location.pathname === '/reviewManagement/viewReview';

  const withoutModifications = isEqual(answers, []) && isEqual(hrSummaryInput, '');

  const handleBack = () => {
    navigate(
      isReviewPage
        ? `/reviewManagement/personalReviews?employeeId=${employeeId}`
        : '/reviewManagement/colleaguesReviews'
    );

    dispatch(setKeyValue({ key: 'answers', value: [] }));
    dispatch(resetState());
  };

  return (
    <>
      <SearchToolbarComponent
        displaySearchIcon={false}
        displaySearchInput={false}
        className={'colleagues-reviews-toolbar'}
        buttons={[
          {
            text: intl.formatMessage({ id: 'back' }),
            action: () => {
              if (withoutModifications) {
                handleBack();
              } else {
                setIsModalOpen(true);
              }
            },
            isBetween: true,
          },
        ]}
      />
      <ModalComponent
        isOpen={isModalOpen}
        className={'modal-warning'}
        isMaskClosable={false}
        width={400}
        title={intl.formatMessage({ id: 'Warning' })}
        children={
          <Typography style={{ fontWeight: '500' }}>{intl.formatMessage({ id: 'ReviewBackPopUpMessage' })}</Typography>
        }
        submitBtnText={intl.formatMessage({ id: 'yes' })}
        cancelBtnText={intl.formatMessage({ id: 'no' })}
        onSubmit={() => {
          handleBack();
          setIsModalOpen(false);
        }}
        onCancelPressed={() => setIsModalOpen(false)}
      />
    </>
  );
};

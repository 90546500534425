import { useIntl } from 'react-intl';

import { Col, Input, Typography } from 'antd';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { IReviewAnswer } from '../../../../redux/slices/personalReviewSlice/types';
import { CustomMultipleChoice, CustomSingleChoice, CustomSlider } from '../../../CustomOptions';
import { ReviewNotes } from '../ReviewNotes';
import './QuestionsFeedback.less';

interface IQuestionsFeedbackProps {
  answerId: number;
  answer: IReviewAnswer;
}

export const QuestionsFeedback = ({ answerId, answer }: IQuestionsFeedbackProps) => {
  const { theme } = useThemeContext();
  const intl = useIntl();

  return (
    <Col className='questions-feedback'>
      <Col>
        <Typography className={`typography-title-body ${theme}`}>
          {intl.formatMessage({ id: 'Question' })} #{answerId + 1}
        </Typography>
        <Typography.Paragraph className={`typography-paragraph ${theme}`}>
          {answer?.reviewQuestion?.questionText}
        </Typography.Paragraph>
        <ReviewNotes question={answer?.reviewQuestion} />
      </Col>
      <Col>
        <Typography className={`typography-title-body ${theme}`}>
          {intl.formatMessage({ id: 'Answer' })} #{answerId + 1}
        </Typography>
        {answer?.reviewQuestion?.answerType === 'Text' && (
          <Typography.Paragraph className={`typography-paragraph ${theme}`}>
            <Input.TextArea
              className={`view-review-input ${theme}`}
              autoSize={{ minRows: 1, maxRows: 4 }}
              value={answer?.textAnswer}
              disabled
            />
          </Typography.Paragraph>
        )}
        {answer?.reviewQuestion?.answerType === 'Rating' && (
          <CustomSlider
            answerOptions={answer?.reviewQuestion?.answerOptions}
            value={answer?.selectedAnswerOptions?.[0]}
            disabled={true}
          />
        )}
        {answer?.reviewQuestion?.answerType === 'SingleChoice' && (
          <CustomSingleChoice
            answerOptions={answer?.reviewQuestion?.answerOptions}
            disabled={true}
            value={answer?.selectedAnswerOptions?.[0]}
          />
        )}
        {answer?.reviewQuestion?.answerType === 'MultipleChoice' && (
          <CustomMultipleChoice
            answerOptions={answer?.reviewQuestion?.answerOptions}
            disabled={true}
            value={answer?.selectedAnswerOptions}
          />
        )}
      </Col>
    </Col>
  );
};

import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useModalContext } from "../../../Modal/ModalProvider";
import ColFormItemComponent from "../../../UIComponents/Form/ColComponent";
import DateRangePicker from "../../../DateRangePicker";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs, { Dayjs } from "dayjs";
import { TimeOffService } from "../../../../services";
import {onFormFinishFailedValidation} from "../../../../utils/onSubmitForm";
import { notifyWithSuccessfulDataUpdated } from "../../../../utils/notificationsUtils";
import { getSuccessMessages } from "../../../../utils/getSuccessMessages";
import { useDispatch } from "react-redux";
import { useColleaguesRequestsFilterContext } from "../../../../context/TimeManagement/ColleaguesRequests/context";
import { TimeOffColleaguesActions } from '../../../../context/TimeManagement/ColleaguesRequests/actions';
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

interface GenerateOrdersFormValue {
    dates: [Dayjs, Dayjs];
}

export const GenerateOrdersForm: React.FC = () => {
    const [pickerSelected, setPickerOpenState] = useState<boolean | undefined>(undefined);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [form] = Form.useForm<GenerateOrdersFormValue>();

    const dispatch = useDispatch();

    const { timeOffColleaguesDispatch, timeOffColleaguesState } =
        useColleaguesRequestsFilterContext();

    const { toggleModal } = useModalContext();
    const intl = useIntl();

    const handleDateChange = (dates: [Dayjs, Dayjs] | null) => {
        if (dates && dates.length === 2) {
            const formattedStartDate: string = dayjs(dates[0]).format('YYYY-MM-DD');
            const formattedEndDate: string = dayjs(dates[1]).format('YYYY-MM-DD');
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
        }
    };

    const onFinish = (values: GenerateOrdersFormValue) => {
        const payload = {
            startDate: startDate,
            endDate: endDate
        };

        TimeOffService.generateOrderNumbers(payload).then(response => {
            const createdCount = response.data["createdCount"];
            const updatedCount = response.data["updatedCount"];

            const createdCountDescription: string = intl.formatMessage(
                {
                    id: "orders_successfully_generated",
                },
                { createdNumber: createdCount }
            );

            const updatedCountDescription: string = intl.formatMessage(
                {
                    id: "orders_affected",
                },
                { updatedNumber: updatedCount }
            );

            notifyWithSuccessfulDataUpdated(getSuccessMessages.success_generate_orders, createdCountDescription, updatedCountDescription);
            toggleModal();
        }).then((() => {
            timeOffColleaguesDispatch({
                type: TimeOffColleaguesActions.INIT_STATE,
                payload: {
                    filterBy: {
                        ...timeOffColleaguesState.filterBy,
                    },
                    filters: {
                        ...timeOffColleaguesState.filters,
                    },
                    pagination: {
                        ...timeOffColleaguesState.pagination,
                    }
                },
            })
        })).catch(error => {
            console.error(error);
        });
    };

    return (
        <Form
            form={form}
            id="generateOrdersForm"
            key='generateOrdersFormKey'
            onFinish={onFinish}
            onFinishFailed={validationError =>
                onFormFinishFailedValidation(validationError, dispatch)
            }
        >
            <ColFormItemComponent
                order={50}
                span={24}
                label={<FormattedMessage id="Dates"/>}
                name="dates"
                rules={[
                {
                    required: true,
                    validator(_, value) {
                    return !value ?
                        Promise.reject(
                            new Error(
                                intl.formatMessage({
                                    id: 'RequestForGenerationOrders'
                                })
                            )
                        )
                        : Promise.resolve();
                    }
                }
            ]}
            >
                <DateRangePicker
                    inputReadOnly={true}
                    disabledKeyboard
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    open={pickerSelected}
                    onOpenChange={e => {
                        if (e === false) {
                            form.validateFields(['dates']);
                        }
                        setPickerOpenState(e);
                    }}
                    onChange={handleDateChange}
                />
            </ColFormItemComponent>
        </Form>
    )
}
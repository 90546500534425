import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ViewReviewContent, ViewReviewToolBar } from '../../../components/ReviewManagement';
import { resetState } from '../../../redux/slices/personalReviewSlice';
import { getReviewByIdAction } from '../../../redux/slices/personalReviewSlice/actions';
import { useAppDispatch } from '../../../redux/store';

export const ViewReview = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const reviewId = Number(searchParams.get('reviewId'));

  useEffect(() => {
    if (reviewId) {
      dispatch(getReviewByIdAction(reviewId));
      dispatch(resetState());
    }
  }, [reviewId]);

  return (
    <>
      <ViewReviewToolBar />
      <br className='break-line' />
      <ViewReviewContent />
    </>
  );
};

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { CalendarSVG } from 'Icons/Menu/calendarIcon';
import { ColleaguesSvg } from 'Icons/Menu/colleagues';
import { DashboardSvg } from 'Icons/Menu/dashboard';
import { InventorySvg } from 'Icons/Menu/inventory';
import { ProfileSvg } from 'Icons/Menu/profile';
import { ReportsSvg } from 'Icons/Menu/reports';
import { SettingsSvg } from 'Icons/Menu/settings';
import { TeamsSvg } from 'Icons/Menu/teams';
import { TicketingSVG } from 'Icons/Menu/ticketingIcon';
import { TimeSvg } from 'Icons/Menu/timeManagement';
import { CustomMenuItemType } from 'components/Layout/Menu/CustomMenuItemType';

import { ReviewSVG } from '../../../Icons/Menu/reviewIcon';

const timeSheetSubItemNew = {
  key: '/timeManagement/timesheet',
  label: (
    <Link to='/timeManagement/timesheet'>
      <FormattedMessage id='AsideMenuBarAndItemsOption_timesheet' />
    </Link>
  ),
};

/** Returns time management menu item with Personal and Colleagues requests by default.
 * If user role is Admin and not team-lead, it returns the timesheet as well */
export const timeManagementItem = (isAdminAndNotTeamLead: boolean, hasTeamOrHasRequiredRoles: boolean) => [
  {
    label: <FormattedMessage id='timeManagement' />,
    key: '/timeManagement',
    icon: <TimeSvg />,
    children: [
      {
        key: '/timeManagement/personalRequests',
        label: (
          <Link to='/timeManagement/personalRequests'>
            <FormattedMessage id='AsideMenuBarAndItemsOption_myRequests' />
          </Link>
        ),
      },
      hasTeamOrHasRequiredRoles
        ? {
            key: '/timeManagement/colleaguesRequests',
            label: (
              <Link to='/timeManagement/colleaguesRequests'>
                <FormattedMessage id='AsideMenuBarAndItemsOption_colleaguesRequests' />
              </Link>
            ),
          }
        : null,
      hasTeamOrHasRequiredRoles
        ? {
            key: '/timeManagement/vacation',
            label: (
              <Link to='/timeManagement/vacation'>
                <FormattedMessage id='AsideMenuBarAndItems_vacations' />
              </Link>
            ),
          }
        : null,
      {
        key: '/timeManagement/attendanceReport',
        label: (
          <Link to='/timeManagement/attendanceReport'>
            <FormattedMessage id='AsideMenuBarAndItems_attendancereport' />
          </Link>
        ),
      },
      isAdminAndNotTeamLead ? { ...timeSheetSubItemNew } : undefined,
    ],
  },
];

export const personalTimeOffRequestsMenuItem = {
  key: '/timeManagement/personalRequests',
  label: (
    <Link to='/timeManagement/personalRequests'>
      <FormattedMessage id='AsideMenuBarAndItems_timeManagement' />
    </Link>
  ),
  icon: <TimeSvg />,
};

export const inventoryMenuItem = (pathname) => {
  return {
    label: (
      <Link to='/inventory/gateway'>
        <FormattedMessage id='AsideMenuBarAndItems_inventory' />
      </Link>
    ),
    key: pathname.includes('inventory') && !pathname.includes('gateway') ? pathname.toString() : '/inventory/software',
    icon: <InventorySvg />,
  };
};

export const teamsMenuItem = (pathname) => {
  return {
    label: (
      <Link to='/teams'>
        <FormattedMessage id='AsideMenuBarAndItems_teams' />
      </Link>
    ),
    key: pathname.includes('teams') ? pathname.toString() : '/teams',
    icon: <TeamsSvg />,
  };
};

export const viewProfileMenuItem = (employeeId) => {
  return {
    label: (
      <Link to={`allUsersList/viewProfile?employeeId=${employeeId}`}>
        <FormattedMessage id='AsideMenuBarAndItems_myProfile' />
      </Link>
    ),
    key: '/allUsersList/viewProfile',
    icon: <ProfileSvg />,
  };
};

export const dashboardMenuItem = {
  label: (
    <Link to={`/dashboard`}>
      <FormattedMessage id='AsideMenuBarAndItems_dashboard' />
    </Link>
  ),
  key: '/dashboard',
  icon: <DashboardSvg />,
};

export const colleaguesMenuItem = {
  label: (
    <Link to='/allUsersList'>
      <FormattedMessage id='AsideMenuBarAndItems_colleagues' />
    </Link>
  ),
  key: '/allUsersList',
  icon: <ColleaguesSvg />,
};

export const calendarMenuItem = {
  label: (
    <Link to='/calendar'>
      <FormattedMessage id='AsideMenuBarAndItems_calendar' />
    </Link>
  ),
  key: '/calendar',
  icon: <CalendarSVG />,
};

export const ticketingMenuItem = (pathname) => {
  return {
    label: (
      <Link to='/ticketing'>
        <FormattedMessage id='MenuItem_Ticketing' />
      </Link>
    ),
    key: pathname.startsWith('/ticketing') ? pathname.toString() : '/ticketing',
    icon: <TicketingSVG />,
  };
};

export const settingsMenuItem = (isAdminAndNotTeamLead: boolean) => [
  {
    label: <FormattedMessage id='MenuItem_Settings' />,
    key: '/settings',
    icon: <SettingsSvg />,
    children: [
      {
        key: '/settings/ticketingCategories',
        label: (
          <Link to='/settings/ticketingCategories'>
            <FormattedMessage id='MenuItem_Ticketing' />
          </Link>
        ),
      },
      {
        key: '/settings/platformSettings',
        label: (
          <Link to='/settings/platformSettings'>
            <FormattedMessage id='platform' />
          </Link>
        ),
      },
    ],
  },
];

export const reportsItem = (pathname) => {
  return {
    label: (
      <Link to='/reports/viewReports'>
        <FormattedMessage id='AsideMenuBarAndItems_reports' />
      </Link>
    ),
    key: pathname.includes('reports') ? pathname.toString() : '/reports',
    icon: <ReportsSvg />,
  };
};

export const items = (): CustomMenuItemType[] => [
  {
    label: (
      <Link to={`/dashboard`}>
        <FormattedMessage id='AsideMenuBarAndItems_dashboard' />
      </Link>
    ),
    key: '/dashboard',
    icon: <DashboardSvg />,
  },
  {
    label: (
      <Link to={`allUsersList/viewProfile?employeeId=1`}>
        <FormattedMessage id='AsideMenuBarAndItems_myProfile' />
      </Link>
    ),
    key: '/allUsersList/viewProfile',
    icon: <ProfileSvg />,
  },
  {
    label: (
      <Link to='/allUsersList'>
        <FormattedMessage id='AsideMenuBarAndItems_colleagues' />
      </Link>
    ),
    key: '/allUsersList',
    icon: <ColleaguesSvg />,
  },
  {
    label: (
      <Link to='/teams'>
        <FormattedMessage id='AsideMenuBarAndItems_teams' />
      </Link>
    ),
    key: '/teams',
    icon: <TeamsSvg />,
  },
  {
    label: (
      <Link to='/inventory/gateway'>
        <FormattedMessage id='AsideMenuBarAndItems_inventory' />
      </Link>
    ),
    key: '/inventory/gateway',
    icon: <InventorySvg />,
  },
  {
    label: (
      <Link to='/timeManagement/personalRequests'>
        <FormattedMessage id='AsideMenuBarAndItems_timeManagement' />
      </Link>
    ),
    key: '/timeManagement/',
    icon: <TimeSvg />,
  },
  {
    label: (
      <Link to='/reports/viewReports'>
        <FormattedMessage id='AsideMenuBarAndItems_reports' />
      </Link>
    ),
    key: '/reports/viewReports',
    icon: <ReportsSvg />,
  },
  {
    label: (
      <Link to='/ticketing'>
        <FormattedMessage id='MenuItem_Ticketing' />
      </Link>
    ),
    key: '/ticketing',
    icon: <TicketingSVG />,
  },
  {
    label: (
      <Link to='/calendar'>
        <FormattedMessage id='AsideMenuBarAndItems_calendar' />
      </Link>
    ),
    key: '/calendar',
    icon: <CalendarSVG />,
  },
  // timeManagementItem
  //* TO DO: for future development
  // {
  //   label: <Link to='/login'>Settings</Link>,
  //   key: '/login',
  //   icon: <SettingsSvg />
  // },
];

/** Returns Review Module  */
export const reviewManagementItem = (employeeId) => {
  return {
    label: <FormattedMessage id='MenuItem_reviewManagement' />,
    key: '/reviewManagement',
    icon: <ReviewSVG />,
    children: [
      {
        key: '/reviewManagement/personalReviews',
        label: (
          <Link to={`/reviewManagement/personalReviews?employeeId=${employeeId}`}>
            <FormattedMessage id='AsideMenuBarAndItemsOption_myReviews' />
          </Link>
        ),
      },
      {
        key: '/reviewManagement/colleaguesReviews',
        label: (
          <Link to='/reviewManagement/colleaguesReviews'>
            <FormattedMessage id='AsideMenuBarAndItemsOption_colleaguesReviews' />
          </Link>
        ),
      },
    ],
  };
};

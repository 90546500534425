import { Radio } from 'antd';

import { useThemeContext } from '../../../AppProvider/ConfigProviderSettings';
import { ICustomOptionProps } from '../type';
import './CustomSingleChoice.less';

export const CustomSingleChoice = (props: ICustomOptionProps) => {
  const { theme } = useThemeContext();
  const { answerOptions, onChange, disabled, value } = props;

  const options = answerOptions.map((option, id) => {
    return { label: option.answerOption, value: option.id };
  });

  return (
    <Radio.Group
      className={`custom-radio ${theme}`}
      options={options}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      value={value}
    />
  );
};

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';

export const PersonalReviewsColumnTable = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const tableColumns = [
    {
      title: `${intl.formatMessage({ id: 'Name' })}`,
      width: '100px',
      key: 'name',
      render: (_, record) => {
        return (
          <Tooltip placement='top' title={`${record.subject?.lastName} ${record.subject?.firstName}`}>
            <Typography.Text
              className='text-medium-big pointer-link line-clamp-1'
              underline
              onClick={() => {
                navigate(`/reviewManagement/viewReview?reviewId=${record.reviewId}`);
              }}
            >
              {record.subject?.lastName} {record.subject?.firstName}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: `${intl.formatMessage({ id: 'Type' })}`,
      width: '100px',
      dataIndex: 'type',
      key: 'type',
      showSorterTooltip: false,
      sorter: true,
      render: (_, record) => (
        <Tooltip placement='top' title={intl.formatMessage({ id: record.type })}>
          <Typography.Text className='text-medium-big pointer-link line-clamp-1'>
            {intl.formatMessage({ id: record.type })}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Started' })}`,
      width: '100px',
      dataIndex: 'startDate',
      key: 'startDate',
      showSorterTooltip: false,
      sorter: true,
      render: (_, record) => (
        <Tooltip placement='top' title={dayjs(record.startDate).format('DD/MM/YYYY')}>
          <Typography.Text className='text-medium-big pointer-link line-clamp-1'>
            {dayjs(record.startDate).format('DD/MM/YYYY')}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Deadline' })}`,
      width: '100px',
      dataIndex: 'endDate',
      key: 'endDate',
      showSorterTooltip: false,
      sorter: true,
      render: (_, record) => (
        <Tooltip placement='top' title={dayjs(record.endDate).format('DD/MM/YYYY')}>
          <Typography.Text className='text-medium-big pointer-link line-clamp-1'>
            {dayjs(record.endDate).format('DD/MM/YYYY')}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'StatusReview' })}`,
      width: '100px',
      key: 'status',
      render: (_, record) => (
        <Tooltip placement='top' title={intl.formatMessage({ id: record.status })}>
          <Typography.Text className='text-medium-big pointer-link line-clamp-1'>
            {intl.formatMessage({ id: record.status })}
          </Typography.Text>
        </Tooltip>
      ),
    },
  ];

  return [...tableColumns];
};

import { useIntl } from 'react-intl';

import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';

import { useMobileContext, useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { ArrowLeft } from '../../../../Icons/Documents/arrowLeft';
import { ArrowRight } from '../../../../Icons/Documents/arrowRight';
import { setKeyValue } from '../../../../redux/slices/personalReviewSlice';
import { IReviewAnswer } from '../../../../redux/slices/personalReviewSlice/types';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import Divider from '../../../Divider';
import { QuestionsFeedback } from '../QuestionsFeedback';

export const ViewReviewFeedbackReviewers = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { theme } = useThemeContext();
  const { deviceHeight } = useMobileContext();
  const { viewReview, currentReviewIndex } = useAppSelector((state) => state.personalReview);

  const { reviewersFeedback, questions } = viewReview;

  const filterReview = reviewersFeedback.filter((review) => review.isProvided === true);
  const currentReview = filterReview?.[currentReviewIndex];
  const currentReviewOwner = currentReview?.owner;
  const currentReviewAnswers = currentReview?.answers;

  const handleNextReview = () => {
    if (currentReviewIndex < filterReview.length - 1)
      dispatch(setKeyValue({ key: 'currentReviewIndex', value: currentReviewIndex + 1 }));
  };

  const handlePreviousReview = () => {
    if (currentReviewIndex > 0) dispatch(setKeyValue({ key: 'currentReviewIndex', value: currentReviewIndex - 1 }));
  };

  return (
    <Row className={`view-feedback-review-template widget-card`} style={{ height: `calc(${deviceHeight}px - 300px)` }}>
      <Col style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Col
          style={{
            paddingLeft: '1em',
            paddingRight: '1em',
            minHeight: currentReview && '120px',
            marginBottom: currentReview && '1em',
          }}
        >
          <Typography.Title level={4}>
            {intl.formatMessage({ id: 'SummaryOfTheFeedbackAlreadyProvided' })}
          </Typography.Title>
          <Divider spacing={16} />
          {currentReview && (
            <div>
              <div className='template-change'>
                <ArrowLeft
                  onClick={() => handlePreviousReview()}
                  className={`splide__arrow ${theme} ${currentReviewIndex === 0 ? 'disabled' : ''}`}
                />
                <Typography
                  className={`typography-title-body employee-name ${theme}`}
                >{`${currentReviewOwner?.firstName} ${currentReviewOwner?.lastName}`}</Typography>
                <ArrowRight
                  onClick={() => handleNextReview()}
                  className={`splide__arrow ${theme} ${currentReviewIndex === filterReview.length - 1 ? 'disabled' : ''}`}
                />
              </div>
              <Typography style={{ textAlign: 'center' }}>{intl.formatMessage({ id: 'submittedOn' })}</Typography>
              <Typography style={{ textAlign: 'center' }}>
                {dayjs.utc(currentReview?.updatedOn).local().format('DD/MM/YYYY HH:mm')}
              </Typography>
            </div>
          )}
        </Col>
        <Col
          className={`review-template ${!currentReview ? 'empty-text' : ''}`}
          style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
        >
          {!currentReview && (
            <div>
              <Typography
                className={`typography-title-body ${theme}`}
                style={{ textAlign: 'center', fontWeight: 400, fontSize: '1rem' }}
              >
                {intl.formatMessage({ id: 'NothingToBeDisplayedHere' })}
              </Typography>
              <Divider spacing={16} />
            </div>
          )}
          {currentReviewAnswers?.map((answer: IReviewAnswer, id: number) => {
            const question = questions.find((q) => q.id === answer?.reviewQuestionId);

            return (
              <div key={id}>
                <QuestionsFeedback answerId={id} answer={{ ...answer, reviewQuestion: question }} />
              </div>
            );
          })}
        </Col>
      </Col>
    </Row>
  );
};

import { useIntl } from 'react-intl';

import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';

import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import styles from '../../../../pages/Ticketing/TicketingDetailedView/Layout/TicketsDetailsSection/ticketsDetailsSection.module.less';
import { IEmployee } from '../../../../redux/slices/colleaguesReviewSlice/types';
import { useAppSelector } from '../../../../redux/store';
import './ColleaguessSummaryReview.less';

export const ColleaguesReviewsSummary = () => {
  const { deviceHeight } = useMobileContext();

  const intl = useIntl();

  const { currentTemplateIndex, reviewTemplates, generalData, employeesList, generalSettings } = useAppSelector(
    (state) => state.colleaguesReview
  );

  const { startDate, endDate, subjectEmployee, reviewers } = generalData;

  const { isSelfAssessmentEnabled, notifySubject } = generalSettings;

  const employee = employeesList?.find((emp: IEmployee) => emp.employeeId === subjectEmployee);

  const reviewersEmployees = reviewers.map((reviewerId: number) =>
    employeesList.find((emp: IEmployee) => emp.employeeId === reviewerId)
  );

  const currentTemplate = reviewTemplates?.[currentTemplateIndex];

  return (
    <div className={`colleagues-review-summary`} style={{ height: `calc(${deviceHeight}px - 300px)` }}>
      <div
        style={{
          overflow: 'auto',
          height: '100%',
          marginRight: '-8px',
        }}
      >
        <div className={`colleagues-review-summary-sections`}>
          <Row className='review-name'>
            <Col>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'ReviewName' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography className='name-text'>{currentTemplate?.name}</Typography>
            </Col>
          </Row>

          <Row className='review-type'>
            <Col>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'Type' })}
              </Typography.Title>
            </Col>
            <Col>
              {currentTemplate?.type && (
                <Typography className='type-text'>{intl.formatMessage({ id: currentTemplate.type })}</Typography>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'Description' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{currentTemplate?.description}</Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'ReviewStatus' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{intl.formatMessage({ id: 'Scheduled' })}</Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'startDate' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{startDate !== '' ? dayjs(startDate).format('DD MMMM YYYY') : '-'}</Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'endDate' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{endDate !== '' ? dayjs(endDate).format('DD MMMM YYYY') : '-'}</Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'subjectEmployee' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>
                {subjectEmployee ? `${employee?.lastName} ${employee?.firstName}, ${employee?.email}` : '-'}
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'Function' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{employee?.position ? intl.formatMessage({ id: employee.position }) : '-'}</Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'TeamsOfTheEmployee' })}
              </Typography.Title>
            </Col>
            <Col style={{ paddingRight: '5px' }}>
              <Typography>
                {subjectEmployee && employee?.teams !== undefined && Object.keys(employee?.teams).length > 0
                  ? Object.values(employee?.teams).join(', ')
                  : '-'}
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'reviewers' })}
              </Typography.Title>
            </Col>
            <Col style={{ paddingRight: '5px' }}>
              <Typography>
                {reviewersEmployees
                  ?.map((reviewer: IEmployee) => `${reviewer.lastName} ${reviewer.firstName}`)
                  .join(', ') || '-'}
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'SelfAssessment' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{intl.formatMessage({ id: isSelfAssessmentEnabled ? 'Yes' : 'No' })}</Typography>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Typography.Title level={5} className={styles.sectionTitle}>
                {intl.formatMessage({ id: 'Notifications' })}
              </Typography.Title>
            </Col>
            <Col>
              <Typography>{intl.formatMessage({ id: notifySubject ? 'Yes' : 'No' })}</Typography>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

import { Form } from 'antd';

import { ColleaguesReviewsContent, ColleaguesReviewsToolbar } from '../../../components/ReviewManagement';

export const ColleaguesReviews = () => {
  const [form] = Form.useForm();

  return (
    <>
      <ColleaguesReviewsToolbar form={form} />
      <br className='break-line' />
      <ColleaguesReviewsContent form={form} />
    </>
  );
};

import { useState, useEffect, cloneElement } from 'react';
import {
  NotificationsSvg,
  UnreadNotificationsSvg
} from 'Icons/notificationComponent';
import { NotificationsService } from 'services';
import {
  INotification,
  NotificationActionType
} from 'services/providers/NotificationsProvider/types';
import NotificationBoxComponent from './NotificationBoxComponent';
import { Dropdown, MenuProps } from 'antd';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { groupBy } from 'utils/arrayUtils';
import './Notifications.less';
import { FormattedMessage } from 'react-intl';
import { CloseSvg } from 'Icons/General/close';
import { ComponentsFlags } from '../../../FeatureToggle/FeatureToggleProvider/ComponentsSettings';

const NotificationsComponent = () => {
  const [notificationsList, setNotificationsList] = useState<INotification[]>(
    []
  );
  const [notificationPopupOpen, setNotificationPopupOpen] = useState(false);
  const [areThereUnreadNotifications, setAreThereUnreadNotifications] =
    useState(false);
  const loadMoreIncreaseBy = 10;
  const [numberOfNotificationsShown, setNumberOfNotificationsShown] =
    useState(loadMoreIncreaseBy);
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const handleOpenChange = (flag: boolean) => setNotificationPopupOpen(flag);
  const [activeKey, setActiveKey] = useState(null);

  const onReceiveNotifications = (notifications: INotification[]) => {
    var ids = new Set(notifications.map(d => d.notificationId));
    setNotificationsList(oldList => {
      const concatinateArrays = [
        ...notifications,
        ...oldList.filter(d => !ids.has(d.notificationId))
      ];
      return concatinateArrays.sort(
        (a, b) => b.notificationId - a.notificationId
      );
    });
    setAreThereUnreadNotifications(
      notifications.some(x => x.readStatus === false)
    );
  };

  const markAllNotificationsAsRead = () => {
    NotificationsService.markAllAsRead();
    const modifiedNotificationList = notificationsList.map(notification => {
      notification.readStatus = true;
      return notification;
    });
    setNotificationsList(modifiedNotificationList);
    setAreThereUnreadNotifications(false);
  };

  const markNotificationAsRead = (notification: INotification) => {
    if (!notification.readStatus) {
      NotificationsService.markNotificationAsRead(notification.notificationId);
      notification.readStatus = true;
      const modifiedNotificationList = [...notificationsList];
      setNotificationsList(modifiedNotificationList);
      setAreThereUnreadNotifications(
        modifiedNotificationList.some(x => x.readStatus === false)
      );
    }
  };

  const loadMore = () =>
    setNumberOfNotificationsShown(oldValue => oldValue + loadMoreIncreaseBy);

  const handleListItemClick = (notificationId: number) => {
  if (activeKey === notificationId) {
      setActiveKey(null); 
    } else {
      setActiveKey(notificationId); 
      var notificationClicked = notificationsList.find(
        n => n.notificationId === notificationId

      );
      markNotificationAsRead(notificationClicked);
    }
   
  };

  const notificationBodyClick = (notificationId: number) => {
    var notificationClicked = notificationsList.find(
      n => n.notificationId === notificationId
    );
    if (notificationClicked) {
      if (
        notificationClicked.actionType.toString() ===
        NotificationActionType[
          NotificationActionType.InformingActionWithRedirect
        ]
      ) {
        markNotificationAsRead(notificationClicked);
        if(notificationClicked?.ticketId) {
          const searchParams = {
            ticketId: notificationClicked?.ticketId?.toString()
          };

          const pathname = `../../ticketing/viewTicket`;
          navigate({
            pathname,
            search: createSearchParams(searchParams).toString()
          });
          setNotificationPopupOpen(false);
        } else if(notificationClicked?.reviewId) {
          const searchParams = {
            reviewId: notificationClicked.reviewId?.toString()
          };

          const pathname = `../../reviewManagement/viewReview`;
          navigate({
            pathname,
            search: createSearchParams(searchParams).toString()
          });
          setNotificationPopupOpen(false);
        } else {
          const pathname = `../../timeManagement/personalRequests`;
          return navigate({ pathname });
        }
      } else if (
        notificationClicked.actionType.toString() ===
        NotificationActionType[NotificationActionType.AwaitingAction]
      ) {
        markNotificationAsRead(notificationClicked);

        const searchParams = {
          requestId: notificationClicked?.requestId?.toString()
        };

        const pathname = `../../timeManagement/colleaguesRequests`;
        navigate({
          pathname,
          search: createSearchParams(searchParams).toString()
        });
        setNotificationPopupOpen(false);
      }
    }
  };

  const onCollapseChange = (eventKeyArray: string[]) => {
    if (eventKeyArray && eventKeyArray.length) {
      var notificationClicked = notificationsList.find(
        n => n.notificationId === parseInt(eventKeyArray[0]) && !n.readStatus
      );
      if (
        notificationClicked &&
        notificationClicked.actionType.toString() ===
          NotificationActionType[NotificationActionType.InformingAction]
      ) {
        markNotificationAsRead(notificationClicked);
      }
    }
  };
  
  const getMenuPropsGrouped = (): MenuProps['items'] => {
  let menuProps = [];
  const groupedByTypeNotifications = groupBy(notificationsList, 'type');
  Object.entries(groupedByTypeNotifications)
    .slice(0, numberOfNotificationsShown)
    .forEach((typeGroup: any[], typeGroupIndex: number) => {
      const groupName = typeGroup[0];
      const notificationGroup = typeGroup[1];
      menuProps.push({
        label: (
          <NotificationBoxComponent
            headerTitle={groupName}
            boxKey={`$_${typeGroupIndex}`}
            activeKeyProprety={false}
            key={`$_${typeGroupIndex}`}
            readStatus={
              !notificationGroup.some(
                (x: INotification) => x.readStatus === false
              )
            }
            unreadCounter={
              notificationGroup.filter(
                (x: INotification) => x.readStatus === false
              ).length || null
            }
            createdDate={
              notificationGroup
                .filter((x: INotification) => x.readStatus === false)
                ?.sort((d: INotification) => d.createdDate)[0]?.createdDate
            }
            itemMessage={notificationGroup.map(
              (notification: INotification) => {
                return (
                  <NotificationBoxComponent
                    headerTitle={notification.type.toString()}
                    boxKey={notification.notificationId}
                    key={notification.notificationId}
                    readStatus={notification.readStatus}
                    createdDate={notification.createdDate}
                    itemMessage={notification.message}
                    notificationBodyClick={notificationBodyClick}
                    onCollapseChange={onCollapseChange}
                    cssClass="innerNotification"
                    activeKeyProprety={false}
                  />
                );
              }
            )}
          />
        ),
       
             
        key: `$_${typeGroupIndex}`
      });
    });

  return menuProps;
};

  const getMenuPropsUngrouped = (): MenuProps['items'] => { // display notification without groups type
    let menuProps = []; 
    Object.entries(notificationsList)
      .slice(0, numberOfNotificationsShown)
      .forEach(([key, notification]: [string, INotification], index: number) => {
        menuProps.push({
          label: (
            <NotificationBoxComponent
              headerTitle={notification.title}
              boxKey={notification.notificationId}
              key={notification.notificationId}
              readStatus={notification.readStatus}
              createdDate={notification.createdDate}
              itemMessage={notification.message}
              notificationBodyClick={notificationBodyClick}
              onCollapseChange={onCollapseChange}
              cssClass="ungrouped_notification"
              activeKey={activeKey}
              activeKeyProprety={true}

            />
          ),
          onClick: () => handleListItemClick(notification.notificationId),
          key: `$_${index}`
        });
      });
  
    return menuProps;
  };
  

  useEffect(() => {
    NotificationsService.subscribeToGetNotifications(onReceiveNotifications);
  }, [NotificationsService.connection]);

  const notificationPopupHeader = () => (
    <div className="notification-popup-header">
      <div className="notification-popup-header-title">
        <FormattedMessage id="NotificationComponent_Title" />
      </div>
      <div
        className="notification-popup-header-close"
        onClick={() => setNotificationPopupOpen(false)}
      >
        <CloseSvg />
      </div>
    </div>
  );

  const notificationPopupFooter = () => (
    <div className="notification-popup-footer">
      <span className="mark-all-as-read" onClick={markAllNotificationsAsRead}>
        <FormattedMessage id="NotificationComponent_mark_all_as_read" />
      </span>
      <span className="load-more" onClick={loadMore}>
        <FormattedMessage id="NotificationComponent_load_more" />
      </span>
    </div>
  );

  return (
    <Dropdown
      className={`notifications-dropdown-component`}
      key="notifications-dropdown-component"
      menu={{ items: ComponentsFlags[0]?.DisplayNotificationGroped ? getMenuPropsGrouped() :getMenuPropsUngrouped()  }}
      dropdownRender={menu => (
        <div className={`notifications-popup-component ${theme}`}>
          {notificationPopupHeader()}
          {cloneElement(menu as React.ReactElement)}
          {notificationPopupFooter()}
        </div>
      )}
      onOpenChange={handleOpenChange}
      open={notificationPopupOpen}
      trigger={['click']}
      destroyPopupOnHide={true}
    >
      <a onClick={e => e.preventDefault()}>
        {areThereUnreadNotifications ? (
          <UnreadNotificationsSvg />
        ) : (
          <NotificationsSvg />
        )}
      </a>
    </Dropdown>
  );
};

export default NotificationsComponent;

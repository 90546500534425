import { Fragment } from 'react';
import { useIntl } from 'react-intl';

import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row, Switch, Tooltip, Typography } from 'antd';

import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import { useAppSelector } from '../../../../redux/store';
import Divider from '../../../Divider';

const settingsOrder = [
  'notifySubject',
  'allowFinishWithoutReviewersFeedback',
  'isReviewersFeedbackVisibleToSubject',
  'isFeedbackVisibleToAllMembers',
  'isHrSummaryVisibleToSubject',
  'isHrSummaryVisibleToReviewers',
  'enableGoalsAndObjectives',
  'isSelfAssessmentEnabled',
  'isReviewHiddenToSubject',
];

export const ViewReviewGeneralSettings = () => {
  const { deviceHeight } = useMobileContext();
  const { viewReview } = useAppSelector((state) => state.personalReview);
  const { settings } = viewReview || {};
  const intl = useIntl();

  const sortedSettings = settingsOrder.map((key) => ({ key, value: settings ? settings[key] : false }));

  return (
    <>
      <Row
        className={'colleagues-reviews-general-settings widget-card'}
        style={{ height: `calc(${deviceHeight}px - 300px)` }}
      >
        <Col style={{ paddingLeft: '1em', paddingRight: '1em' }}>
          <Typography.Title level={4}>
            {intl.formatMessage({ id: 'ViewGeneralSettingsForThisRequest' })}
          </Typography.Title>
          <Divider spacing={16} />
        </Col>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
          {settings &&
            sortedSettings.map(({ key, value }) => (
              <Fragment key={key}>
                <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Col style={{ display: 'flex', gap: '0.5em' }}>
                    <Typography className='typography-title-body'>{intl.formatMessage({ id: key })}</Typography>
                    <Tooltip title={intl.formatMessage({ id: `${key}_Tooltip` })}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Switch
                    checked={value as boolean}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    disabled
                  />
                </Col>
              </Fragment>
            ))}
        </div>
      </Row>
    </>
  );
};

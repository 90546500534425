import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Col, Form, Input, Row, Typography } from 'antd';

import { useMobileContext, useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { EditIcon } from '../../../../Icons/Colleagues/EditIcon';
import { ArrowLeft } from '../../../../Icons/Documents/arrowLeft';
import { ArrowRight } from '../../../../Icons/Documents/arrowRight';
import { RemoveQuestionIcon } from '../../../../Icons/Review/removeQuestionIcon';
import {
  deleteCustomQuestion,
  setCurrentTemplateIndex,
  setCustomQuestions,
  updateCustomQuestions,
} from '../../../../redux/slices/colleaguesReviewSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { ButtonCustom } from '../../../Buttons';
import { CustomMultipleChoice, CustomSingleChoice, CustomSlider } from '../../../CustomOptions';
import Divider from '../../../Divider';
import ModalComponent from '../../../Modal';
import { useModalContext } from '../../../Modal/ModalProvider';
import ColFormItemComponent from '../../../UIComponents/Form/ColComponent';
import { setFormItemRules } from '../../../UIComponents/Form/ColComponent/utils';
import { ReviewNotes } from '../../ViewReview/ReviewNotes';
import './ColleaguesReviewsTemplate.less';

interface IOpenModalDefault {
  addCustomQuestion: boolean;
  editCustomQuestion: boolean;
}

export const OpenModalDefault: IOpenModalDefault = {
  addCustomQuestion: false,
  editCustomQuestion: false,
};

export const ColleaguesReviewTemplate = () => {
  const [openModal, setOpenModal] = useState(OpenModalDefault);
  const [inputQuestion, setInputQuestion] = useState<string>('');
  const [selectedCustomQuestion, setSelectedCustomQuestion] = useState<number | null>(null);

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [form] = Form.useForm();

  const { theme } = useThemeContext();
  const { deviceHeight } = useMobileContext();
  const { toggleModal } = useModalContext();
  const { reviewTemplates, currentTemplateIndex, customQuestions } = useAppSelector((state) => state.colleaguesReview);

  const currentTemplate = reviewTemplates?.[currentTemplateIndex];
  const currentQuestions = reviewTemplates[currentTemplateIndex]?.questions;
  const questionsLength = currentQuestions?.length + 1;

  const handleOpenModalCustomQuestion = () => {
    setOpenModal({ addCustomQuestion: true, editCustomQuestion: false });
    toggleModal();
  };

  const handleInputQuestion = (e) => {
    setInputQuestion(e.target.value);
  };

  const handleCancelModal = () => {
    form.resetFields();
    setOpenModal({ addCustomQuestion: false, editCustomQuestion: false });
    toggleModal();
  };

  const handleAddQuestion = () => {
    form.validateFields();
    if (inputQuestion.trim()) {
      dispatch(setCustomQuestions({ text: inputQuestion, notes: '' }));
      setInputQuestion('');
      form.resetFields();
      setOpenModal({ addCustomQuestion: false, editCustomQuestion: false });
      toggleModal();
    }
  };

  const handleOpenEditCustomQuestion = (question: string, index: number) => {
    setOpenModal({ addCustomQuestion: false, editCustomQuestion: true });
    setInputQuestion(question);
    setSelectedCustomQuestion(index);
    form.setFieldsValue({ editCustomQuestion: question });
    toggleModal();
  };

  const handleEditCustomQuestion = () => {
    if (selectedCustomQuestion !== null && inputQuestion.trim()) {
      const payload = { index: selectedCustomQuestion, question: inputQuestion };
      dispatch(updateCustomQuestions(payload));
      setInputQuestion('');
      setSelectedCustomQuestion(null);
      setOpenModal({ addCustomQuestion: false, editCustomQuestion: false });
      toggleModal();
    }
  };

  const handleDeleteCustomQuestion = (index: number) => {
    setInputQuestion('');
    dispatch(deleteCustomQuestion(index));
  };

  const handleNextTemplate = () => {
    if (currentTemplateIndex < reviewTemplates.length - 1) {
      dispatch(setCurrentTemplateIndex(currentTemplateIndex + 1));
    }
  };

  const handlePreviousTemplate = () => {
    if (currentTemplateIndex > 0) {
      dispatch(setCurrentTemplateIndex(currentTemplateIndex - 1));
    }
  };

  return (
    <Row className={`colleagues-review-template widget-card`} style={{ height: `calc(${deviceHeight}px - 300px)` }}>
      <Col style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Col style={{ paddingLeft: '1em', paddingRight: '1em' }}>
          <Typography.Title level={4}>{intl.formatMessage({ id: 'ScheduleOrCreateReview' })}</Typography.Title>
          <Divider spacing={16} />
          <div className='template-change'>
            <ArrowLeft
              onClick={() => handlePreviousTemplate()}
              className={`splide__arrow ${theme} ${currentTemplateIndex === 0 ? 'disabled' : ''}`}
            />
            <Typography className={`typography-title-body ${theme}`} style={{ width: '205px', textAlign: 'center' }}>
              {currentTemplate?.name}
            </Typography>
            <ArrowRight
              onClick={() => handleNextTemplate()}
              className={`splide__arrow ${theme} ${currentTemplateIndex === reviewTemplates.length - 1 ? 'disabled' : ''}`}
            />
          </div>
        </Col>
        <Col className={'review-template'}>
          {currentQuestions?.map((question, id) => {
            return (
              <div key={id}>
                <Col>
                  <Typography className={`typography-title-body ${theme}`}>
                    {intl.formatMessage({ id: 'Question' })} #{id + 1}
                  </Typography>
                  <Typography.Paragraph className={`typography-paragraph ${theme}`}>
                    {question?.questionText}
                  </Typography.Paragraph>
                  <ReviewNotes question={question} />
                </Col>
                <Col>
                  <Typography className={`typography-title-body ${theme}`}>
                    {intl.formatMessage({ id: 'Answer' })} #{id + 1}
                  </Typography>
                  {question?.answerType === 'Text' && (
                    <Typography.Paragraph className={`typography-paragraph ${theme}`}>
                      <Input.TextArea
                        className={`colleagues-review-input ${theme}`}
                        autoSize={{ minRows: 1, maxRows: 4 }}
                        disabled
                      />
                    </Typography.Paragraph>
                  )}
                  {question?.answerType === 'Rating' && (
                    <CustomSlider answerOptions={question?.answerOptions} disabled={true} />
                  )}
                  {question?.answerType === 'SingleChoice' && (
                    <CustomSingleChoice answerOptions={question?.answerOptions} disabled={true} />
                  )}
                  {question?.answerType === 'MultipleChoice' && (
                    <CustomMultipleChoice answerOptions={question?.answerOptions} disabled={true} />
                  )}
                </Col>
              </div>
            );
          })}
          {customQuestions?.map((customQuestion, index) => {
            return (
              <div key={index}>
                <Col>
                  <div style={{ display: 'flex', gap: '1em', paddingTop: '5px' }}>
                    <Typography className={`typography-title-body ${theme}`}>
                      {intl.formatMessage({ id: 'Question' })} #{questionsLength + index}
                    </Typography>
                    <ButtonCustom
                      className={`text-bold-normal btn-default-custom edit-btn ${theme}`}
                      onClick={() => handleOpenEditCustomQuestion(customQuestion?.text, index)}
                    >
                      <EditIcon />
                    </ButtonCustom>
                    <ButtonCustom
                      className={`text-bold-normal btn-default-custom remove-btn ${theme}`}
                      onClick={() => handleDeleteCustomQuestion(index)}
                    >
                      <RemoveQuestionIcon />
                    </ButtonCustom>
                  </div>
                  <Typography.Paragraph className={`typography-paragraph ${theme}`}>
                    {customQuestion?.text}
                  </Typography.Paragraph>
                </Col>
                <Col>
                  <Typography className={`typography-title-body ${theme}`}>
                    {intl.formatMessage({ id: 'Answer' })} #{questionsLength + index}
                  </Typography>
                  <Typography.Paragraph className={`typography-paragraph ${theme}`}>
                    <Input.TextArea
                      className={`colleagues-review-input ${theme}`}
                      autoSize={{ minRows: 1, maxRows: 4 }}
                      disabled
                    />
                  </Typography.Paragraph>
                </Col>
              </div>
            );
          })}
          <Col className={'colleagues-review-template-button'}>
            <ButtonCustom
              className={`text-bold-normal btn-default-custom ${theme}`}
              style={{ marginBottom: '5px' }}
              onClick={() => handleOpenModalCustomQuestion()}
            >
              {intl.formatMessage({ id: 'addQuestion' })}
            </ButtonCustom>
          </Col>
          {openModal.addCustomQuestion && (
            <ModalComponent
              title={intl.formatMessage({ id: 'AddCustomQuestion' })}
              submitBtnText={intl.formatMessage({ id: 'add' })}
              cancelBtnText={intl.formatMessage({ id: 'cancel' })}
              onCancelPressed={handleCancelModal}
              onSubmit={handleAddQuestion}
              children={
                <Form form={form}>
                  <ColFormItemComponent
                    name='addCustomQuestion'
                    label={intl.formatMessage({ id: 'InputYourQuestion' })}
                    rules={setFormItemRules({
                      isRequired: true,
                      requiredMessage: intl.formatMessage({ id: 'AddCustomQuestionValidation' }),
                    })}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 1, maxRows: 4 }}
                      maxLength={500}
                      value={inputQuestion}
                      onChange={(e) => handleInputQuestion(e)}
                    />
                  </ColFormItemComponent>
                </Form>
              }
            />
          )}
          {openModal.editCustomQuestion && (
            <ModalComponent
              title={intl.formatMessage({ id: 'EditCustomQuestion' })}
              submitBtnText={intl.formatMessage({ id: 'edit' })}
              onCancelPressed={handleCancelModal}
              onSubmit={handleEditCustomQuestion}
              children={
                <Form form={form}>
                  <ColFormItemComponent
                    name={'editCustomQuestion'}
                    label={intl.formatMessage({ id: 'EditYourQuestion' })}
                    rules={setFormItemRules({
                      isRequired: true,
                      requiredMessage: intl.formatMessage({ id: 'EditCustomQuestionValidation' }),
                    })}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 1, maxRows: 4 }}
                      maxLength={500}
                      value={inputQuestion}
                      onChange={(e) => handleInputQuestion(e)}
                    />
                  </ColFormItemComponent>
                </Form>
              }
            />
          )}
        </Col>
      </Col>
    </Row>
  );
};

import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Col, Form, Input, Row, Typography } from 'antd';

import { useThemeContext } from '../../../../AppProvider/ConfigProviderSettings';
import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import { setKeyValue } from '../../../../redux/slices/personalReviewSlice';
import { submitHrSummaryAction } from '../../../../redux/slices/personalReviewSlice/actions';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useAuth } from '../../../../services/providers/AuthProvider';
import { notifyWithError, notifyWithSuccessfulDataUpdated } from '../../../../utils/notificationsUtils';
import { allowPermision, userRoles } from '../../../../utils/userManagement';
import { ButtonCustom } from '../../../Buttons';
import Divider from '../../../Divider';

export const ViewReviewHrSummary = ({ maxRows }) => {
  const { theme } = useThemeContext();
  const { deviceHeight } = useMobileContext();
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const employeeId = useAppSelector((state) => state.user?.loggedUser?.employeeId);
  const { viewReview, hrSummaryInput } = useAppSelector((state) => state.personalReview);
  const { generalData, hrSummary } = viewReview;
  const { createdBy } = generalData;

  const [inputValue, setInputValue] = useState<string>(hrSummary);

  const isCreator = createdBy?.employeeId === employeeId;
  const isAdmin = allowPermision(user.role, [userRoles.Admin]);
  const reviewId = generalData?.reviewId;

  const isHrVisibleToSend = generalData?.status === 'UnderReview' && (isCreator || isAdmin) && !hrSummary;
  const isNothingToBeDisplayed =
    (!hrSummary && !isCreator && !isAdmin) ||
    ((generalData?.status === 'Ongoing' || generalData?.status === 'Cancelled') && (isCreator || isAdmin));
  const isHrTextarea = isHrVisibleToSend || hrSummary;
  const isInputDisabled = hrSummary || (generalData?.status !== 'UnderReview' && (!isCreator || isCreator));

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleOnBlur = () => {
    dispatch(setKeyValue({ key: 'hrSummaryInput', value: inputValue }));
  };

  const handleSubmit = async () => {
    const payload = {
      reviewId: reviewId,
      summary: inputValue,
    };

    await form.validateFields();

    try {
      dispatch(submitHrSummaryAction(payload));
      dispatch(setKeyValue({ key: 'viewReview', value: { hrSummary: inputValue } }));
      dispatch(setKeyValue({ key: 'hrSummaryInput', value: '' }));
      notifyWithSuccessfulDataUpdated('', intl.formatMessage({ id: 'SummarySubmittedSuccessfully' }));
    } catch {
      notifyWithError({
        message: intl.formatMessage({ id: 'Error' }),
        description: intl.formatMessage({ id: 'AnErrorOccurredWhileSubmittingYourSummary' }),
      });
    }
  };

  return (
    <Row
      className='colleagues-review-goals widget-card'
      style={{ height: `calc(${deviceHeight}px - 300px)`, gap: '0' }}
    >
      <Col style={{ paddingLeft: '1em', paddingRight: '1em' }}>
        <Typography.Title level={4}>{intl.formatMessage({ id: 'SummaryProvidedByTheHR' })}</Typography.Title>
        <Divider spacing={16} />
      </Col>
      <Col className='empty-text'>
        {isNothingToBeDisplayed && (
          <div>
            <Typography
              className={`typography-title-body ${theme}`}
              style={{ textAlign: 'center', fontWeight: 400, fontSize: '1rem' }}
            >
              {intl.formatMessage({ id: 'NothingToBeDisplayedHere' })}
            </Typography>
            <Divider spacing={16} />
          </div>
        )}
        {/*(((isCreator || isAdmin) && !hrSummary && generalData?.status === 'UnderReview') || hrSummary)*/}
        {isHrTextarea && (
          <Form form={form} className='colleagues-review-goals-form'>
            <Form.Item
              name='hrSummary'
              initialValue={hrSummaryInput || hrSummary}
              rules={[
                {
                  required: true,
                  message: 'Please enter your summary',
                },
              ]}
            >
              <Input.TextArea
                style={{ width: '100%' }}
                autoSize={{ minRows: maxRows, maxRows: maxRows }}
                className={`input-textarea ${theme}`}
                maxLength={4000}
                {...(isHrVisibleToSend && { showCount: true })}
                value={inputValue}
                disabled={isInputDisabled}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
            </Form.Item>
            {isHrVisibleToSend && (
              <Col style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonCustom
                  onClick={handleSubmit}
                  className={`text-bold-normal btn-default-custom ${theme}`}
                  style={{ width: '123px', marginBottom: '5px' }}
                >
                  {intl.formatMessage({ id: 'send' })}
                </ButtonCustom>
              </Col>
            )}
          </Form>
        )}
      </Col>
    </Row>
  );
};

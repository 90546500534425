import { useEffect } from 'react';

interface IUseResizeTruncation {
  ref: React.RefObject<HTMLElement>;
  setEllipsed: (isTruncated: boolean) => void;
  deps?: any[];
}

export function useResizeTruncation({ ref, setEllipsed, deps = [] }: IUseResizeTruncation) {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (ref.current) {
        const isTruncated = ref.current.scrollWidth > ref.current.clientWidth;
        setEllipsed(isTruncated);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, deps);
}

import { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row, Switch, Tooltip, Typography } from 'antd';

import { useMobileContext } from '../../../../AppProvider/ConfigProviderSettings';
import { setKeyValue } from '../../../../redux/slices/colleaguesReviewSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import Divider from '../../../Divider';
import './ColleaguesReviewsGeneralSettings.less';

const disableKeysForHideReview = [
  'notifySubject',
  'isSelfAssessmentEnabled',
  'isHrSummaryVisibleToSubject',
  'isReviewersFeedbackVisibleToSubject',
];

export const ColleaguesReviewsGeneralSettings = () => {
  const { deviceHeight } = useMobileContext();
  const dispatch = useAppDispatch();
  const { generalSettings } = useAppSelector((state) => state.colleaguesReview);
  const intl = useIntl();

  const handleSwitchChange = (key: string, checked: boolean) => {
    dispatch(setKeyValue({ key: 'generalSettings', value: { [key]: checked } }));
  };

  useEffect(() => {
    if (generalSettings.isReviewHiddenToSubject) {
      disableKeysForHideReview.forEach((key) => {
        dispatch(setKeyValue({ key: 'generalSettings', value: { [key]: false } }));
      });
    }
  }, [generalSettings.isReviewHiddenToSubject]);

  return (
    <>
      <Row
        className={'colleagues-reviews-general-settings widget-card'}
        style={{ height: `calc(${deviceHeight}px - 300px)` }}
      >
        <Col style={{ paddingLeft: '1em', paddingRight: '1em' }}>
          <Typography.Title level={4}>{intl.formatMessage({ id: 'ConfigureTheReview' })}</Typography.Title>
          <Divider spacing={16} />
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
          {Object.entries(generalSettings).map(([key, value]) => (
            <Fragment key={key}>
              <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col style={{ display: 'flex', gap: '0.5em' }}>
                  <Typography className='typography-title-body'>{intl.formatMessage({ id: key })}</Typography>
                  <Tooltip title={intl.formatMessage({ id: `${key}_Tooltip` })}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Col>
                <Switch
                  checked={value as boolean}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked) => handleSwitchChange(key, checked)}
                  disabled={generalSettings.isReviewHiddenToSubject && disableKeysForHideReview.includes(key)}
                />
              </Col>
            </Fragment>
          ))}
        </Col>
      </Row>
    </>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { ReviewService } from '../../../services';
import { IAddReview } from '../../../services/providers/ReviewProvider/types';
import { IFilters } from '../personalReviewSlice/types';

export const getReviewTemplatesAction = createAsyncThunk(
  'ReviewTemplate/getTemplates',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ReviewService.getReviewTemplates();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllowedAssigneesAction = createAsyncThunk(
  'Review/getAllowedAssignees',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ReviewService.getAllowedAssignees();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllowedAssigneesReviewersAction = createAsyncThunk(
  'Review/getAllowedAssigneesReviewers?subjectEmployeeId=',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await ReviewService.getAllowedAssigneesReviewers(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addReviewAction = createAsyncThunk(
  'Review/addReview',
  async (payload: IAddReview, { rejectWithValue }) => {
    try {
      const response = await ReviewService.addReview(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllReviewsAction = createAsyncThunk(
  'Review/getAllReviews',
  async (payload: IFilters, { rejectWithValue }) => {
    try {
      const response = await ReviewService.getAllReviews(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
